import RoutedButton from "./RoutedButton";

const ProductsPageButton = ({ large }) => {
  const path = "/products/";
  const buttonColor = "inherit";
  const style = large ? { width: "168px", height: "48px", fontSize: "15px" } : { width: "148px", height: "42px" };
  return (
    <RoutedButton path={path} buttonColor={buttonColor} buttonStyle={style}>
      Explore Products
    </RoutedButton>
  );
};

export default ProductsPageButton;
