import { Container } from "@mui/material";
import { ReactNode } from "react";

import PageTitle from "src/custom_components/PageTitle";

interface PageTemplateProps {
  title?: string;
  children: ReactNode;
}

const PageTemplate = ({ title, children }: PageTemplateProps) => {
  return (
    <Container
      sx={{
        minHeight: 1,
        pt: { xs: 12, md: 14 },
        pb: { xs: 6, md: 6 },
      }}
    >
      {title && <PageTitle title={title} />}
      {children}
    </Container>
  );
};

export default PageTemplate;
