import { Stack, IconButton, Typography } from "@mui/material";
import { useState } from "react";

import Iconify from "src/components/icon/Iconify";
import Image from "src/components/image/Image";
import ResourceCost from "src/newComponents/ResourceCost";

export default function CartItemsDesktop({ items, removeItemFromBasket }) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: 2,
          minWidth: 520,
          typography: "subtitle2",
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Stack flexGrow={1}>Item</Stack>
        <Stack
          sx={{
            width: 114,
          }}
        >
          Price
        </Stack>
      </Stack>

      {items.map((item) => (
        <CartItem key={item.id} item={item} removeItemFromBasket={removeItemFromBasket} />
      ))}
    </>
  );
}

function CartItem({ item, removeItemFromBasket }) {
  const [isLoading, setIsLoading] = useState(false);

  const removeFromBasket = (item) => {
    setIsLoading(true);
    const postAction = () => setIsLoading(false);
    removeItemFromBasket(item.stripePriceId, item.resourceId, postAction);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        py: 3,
        minWidth: 500,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Stack direction="row" alignItems="center" flexGrow={1}>
        <Image
          src={item.image}
          sx={{
            width: 80,
            height: 80,
            flexShrink: 0,
            borderRadius: 1.5,
            bgcolor: "background.neutral",
          }}
        />

        <Stack spacing={0.5} sx={{ p: 2 }}>
          <Typography variant="subtitle2">{item.name}</Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {item.description}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1.5} justifyContent="end" style={{ marginBottom: "1rem" }}>
        <ResourceCost cost={item.cost} originalCost={item.originalCost} paddingTop={true} />

        <IconButton disabled={isLoading} onClick={() => removeFromBasket(item)}>
          <Iconify icon="carbon:trash-can" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
