import { Typography } from "@mui/material";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import klarnaLogo from "src/assets/klarna.png";
import AnswerLink from "src/config/faq/answer/AnswerLink";
import { AccessContext } from "src/context/AccessContext";
import PageTitle from "src/custom_components/PageTitle";
import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";
import PageTemplate from "src/pages/PageTemplate";

import ProductItems from "./components/ProductItems";
import ProductsPageTopSection from "./components/ProductsPageTopSection";
import MyCard from "./MyCard";

const filterForProductsPage = (resources: ResourceModel[] | null) => {
  if (resources === null) return null;
  return resources.filter((resource) => resource.availableOnProductsPage);
};

const ProductsPage = () => {
  const { getAllResourceModelsByResourceType } = useContext(AccessContext);

  const courseResourceModels = filterForProductsPage(getAllResourceModelsByResourceType(ResourceModelType.course));
  const questionBankResourceModels = filterForProductsPage(
    getAllResourceModelsByResourceType(ResourceModelType.questionBank)
  );
  const mockPaperResourceModels = filterForProductsPage(
    getAllResourceModelsByResourceType(ResourceModelType.mockPaper)
  );
  const workshopResourceModels = filterForProductsPage(getAllResourceModelsByResourceType(ResourceModelType.workshop));

  const cheapestCourseResource = courseResourceModels?.reduce((prev, current) =>
    prev.cost < current.cost ? prev : current
  );

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/products/" }]}>
        <title>Products | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Discover the all in one comprehensive Foundation Pharmacist Training programme with an approved GPhC training plan, browse our specialised programme and exam revision courses."
        />
        <meta
          name="keywords"
          content="Foundation Pharmacist Training Programme, GPhC Approved Training Plan, Pharmacy Exam Revision Courses, Pre-registration Pharmacist Training, Pharmacy Careers, Foundation Pharmacist Courses, BNF exam revision, Calculations questions practice, GPhC exam revision"
        />
      </Helmet>
      <PageTemplate>
        <ProductsPageTopSection />
        <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginTop: "1rem" }}>
          <img src={klarnaLogo} alt="Klarna" title="Klarna" style={{ width: "70px", height: "39px" }} loading="eager" />
          <p>
            All our courses are now available with monthly instalment options with no deposit and no interest, split
            your purchase over 12 months and get full access to your courses straight away. Select Klarna at the
            checkout to view instalment options available to you.{" "}
            <AnswerLink href="https://www.klarna.com/uk/terms-and-conditions/">Klarna T&C's</AnswerLink>, for more info
            contact our support team.
          </p>
        </div>
        <MyCard customStyle={{ marginTop: "1rem" }}>
          <PageTitle title="Exam Revision Courses & Workshops" />
          <p>All our exam revision courses, question bank and mock paper are included in the all in one programme.</p>
          <p>
            This section is for anyone who is interested in accessing specific self study revision courses, especially
            for the GPhC exam.
          </p>
          <p>They're popular with students, Trainee Pharmacists and Pharmacists too. Start your exam revision today!</p>

          <ProductItems
            title="Exam Revision Courses"
            link="/courses/"
            content={
              <>
                <p style={{ marginTop: 0 }}>
                  All these courses are included in our{" "}
                  <AnswerLink href="/foundation-training-programme/">All In One Programme</AnswerLink>.
                </p>
                <p>
                  Interested in specific courses only? Select your courses here which include recorded lessons and
                  workbooks to complete in your own time.
                </p>
                <p>Everyone is given 365 days access from enrolment date, start your revision today!</p>
                <p style={{ marginBottom: 0 }}>
                  Interested in the Live Online Study Sessions / Telegram group? Please select the all in one programme,
                  as the separate courses are designed to be self study and not eligible for the live online study
                  sessions and Telegram group.
                </p>
              </>
            }
            resourceModels={courseResourceModels}
            klarnaCost={cheapestCourseResource?.cost}
          />
          <ProductItems
            title="Question Bank"
            link="/question-bank/"
            content="Access over 1000 GPhC style questions to practice for your revision, we have single best answer, extended matching and Calculations questions. Build your confidence and practice questions now."
            resourceModels={questionBankResourceModels}
          />
          <ProductItems
            title="Mock Paper"
            link="/mock-paper/"
            content="Experience the full GPhC exam day simulation with our comprehensive online mock paper, which covers Part 1 Calculations and Part 2 Clinical papers. Boost your confidence, refine your exam strategy, and excel on exam day with our popular mock paper."
            resourceModels={mockPaperResourceModels}
          />
          <ProductItems
            title="Workshops"
            link="/career-workshops"
            content="Considering your Pharmacy Career options? Join our live full day workshops, Hospital & Prescribing but more to come! Learn the necessary steps to transition to another sector the easy way with the guidance of our expert experienced tutors, who will guide you through your new career role, There may be Pharmacist job opportunities available too!"
            resourceModels={workshopResourceModels}
          />
        </MyCard>

        <Typography variant="body2" sx={{ mt: 4 }}>
          Pre Reg Shortcuts Ltd is authorised and regulated by the Financial Conduct Authority (FRN: 1010725). We act as
          a credit broker not a lender. We will only introduce you to Klarna Financial Services UK Ltd (FRN: 1010725).
          Klarna may offer you their unregulated 'pay in three instalments' or 'pay in 30 days' finance products (both
          are interest-free, 0% APR). They may also offer you their FCA regulated extended term finance plan (up to 12
          months), this option will also be interest-free (0% APR). The finance options available will be clearly
          displayed at checkout under 'Financing'. If you would like to know how we handle complaints, please ask for a
          copy of our complaints handling process. You can also find information about referring a complaint to the
          Financial Ombudsman Service (FOS) at financial-ombudsman.org.uk. This service is not available for the
          unregulated 'pay in three instalments' or 'pay in 30 days' Klarna finance products.
        </Typography>
      </PageTemplate>
    </>
  );
};

export default ProductsPage;
