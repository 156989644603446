import { Alert, Button, Typography } from "@mui/material";

import Iconify from "src/components/icon/Iconify";
import ConfigType from "src/models/ConfigType";
import ConfigurationModel from "src/models/ConfigurationModel";

const DSPSupportGroup = ({
  userHasAccess,
  configurations,
}: {
  userHasAccess: boolean;
  configurations: ConfigurationModel[];
}) => {
  const configurationModel = configurations.find(
    (c) => c.type === ConfigType.linkToJoinForDesignatedSupervisorSupportGroup
  );

  return (
    <>
      <p style={{ marginBottom: 0 }}>
        Join our Support Group select the link below, this group is exclusively for designated supervisors with Pre-Reg
        Shortcuts only. Please provide your name, contact number and proof of purchase.
      </p>
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        It may take up to 3 working days (excluding weekends) to be added to this group.
      </Typography>

      {!userHasAccess && (
        <Alert icon={<Iconify width={24} icon={"carbon:locked"} />} severity="warning">
          The Designated Supervisor Programme requires access. <br />
          For more details, contact us at{" "}
          <a className="faqPageLink" target="_blank" href="mailto:info@preregshortcuts.com" rel="noreferrer">
            info@preregshortcuts.com
          </a>
          .
        </Alert>
      )}
      {userHasAccess && (
        <>
          {configurationModel ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              href={configurationModel.value}
              target="_blank"
              rel="noreferrer"
            >
              Join Support Group (Telegram)
            </Button>
          ) : (
            <>Failed to display "Register for Support Group" button.</>
          )}
        </>
      )}
    </>
  );
};

export default DSPSupportGroup;
