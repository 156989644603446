import axios from "axios";
import { useEffect, useState } from "react";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import MockPaperModel from "src/models/MockPaperModel";
import QuestionSetModel from "src/models/QuestionSetModel";
import { mockPapersEndpoint } from "src/urls";

interface MockPaperDto {
  id: number;
  title: string;
  description: string;
  resource: number;
  question_sets: any[];
}

interface QuestionSetDto {
  id: number;
  title: string;
  duration: number;
  num_questions: number;
  mock_paper_order: number;
  resource: number;
}

const mapMockPaperDtoToModel = (mockPaperDto: MockPaperDto) => {
  return {
    mockPaperId: mockPaperDto.id,
    title: mockPaperDto.title,
    description: mockPaperDto.description,
    resourceId: mockPaperDto.resource,
    questionSets: mockPaperDto.question_sets.map(mapQuestionSetDtoToModel),
  } as MockPaperModel;
};

const mapQuestionSetDtoToModel = (questionSetDto: QuestionSetDto) => {
  return {
    questionSetId: questionSetDto.id,
    title: questionSetDto.title,
    duration: questionSetDto.duration,
    numQuestions: questionSetDto.num_questions,
    mockPaperOrder: questionSetDto.mock_paper_order,
    resourceId: questionSetDto.resource,
  } as QuestionSetModel;
};

const useMockPapers = () => {
  const isLightMode = useIsLightMode();

  const [mockPapers, setMockPapers] = useState<MockPaperModel[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get<MockPaperDto[]>(mockPapersEndpoint)
      .then((response) => {
        const mockPapers = response.data.map(mapMockPaperDtoToModel);
        setMockPapers(mockPapers);
      })
      .catch(() => errorToast("Error loading mock papers, please try again later.", isLightMode))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { mockPapers, isLoading };
};

export default useMockPapers;
