import axios from "axios";

import ResourceModel from "src/models/ResourceModel";
import { AccessInstruction } from "src/pages/admin_dashboard/components/manage_access_helpers/GiveAccess";
import DateService from "src/utils/dateService";

import ResourceDto from "./dtos/ResourceDto";
import mapResourceDtoToModel from "./mappers/mapResource";

interface AccessInstructionDto {
  resourceId: number;
  crudAction: "create" | "update" | "delete";
  accessStart: string | null;
  accessEnd: string | null;
}

interface IAdminRepository {
  getUserResources(userId: number): Promise<ResourceModel[]>;
  executeAccessInstructions(userId: number, accessInstructions: AccessInstruction[], note: string): Promise<string>;
  generateProgressReport(userId: number): Promise<void>;
}

class AdminRepository implements IAdminRepository {
  async getUserResources(userId: number): Promise<ResourceModel[]> {
    try {
      const response = await axios.get<ResourceDto[]>(`/api/resource/get/${userId.toString()}/`);
      if (response.status !== 200) throw new Error();
      return response.data.map(mapResourceDtoToModel);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch resources.");
    }
  }

  async executeAccessInstructions(
    userId: number,
    accessInstructions: AccessInstruction[],
    note: string
  ): Promise<string> {
    const accessInstructionDtos: AccessInstructionDto[] = accessInstructions.map((instruction) => {
      return {
        resourceId: instruction.resource.id,
        crudAction: instruction.crudAction,
        accessStart: instruction.accessStart ? DateService.formatForAPI(instruction.accessStart) : null,
        accessEnd: instruction.accessEnd ? DateService.formatForAPI(instruction.accessEnd) : null,
      };
    });
    try {
      const response = await axios.post(`/api/resource/execute_access_instructions/${userId.toString()}/`, {
        access_instructions: accessInstructionDtos,
        note: note,
      });
      if (response.status !== 200) throw new Error();
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to execute access instructions.");
    }
  }

  async generateProgressReport(userId: number): Promise<void> {
    try {
      const response = await axios.get(`/api/admin/generate_trainee_report/${userId.toString()}/`, {
        responseType: "blob",
      });
      window.open(URL.createObjectURL(new Blob([response.data], { type: "application/pdf" })));
      if (response.status !== 200) throw new Error();
    } catch (error) {
      console.error(error);
      throw new Error("Failed to generate progress report.");
    }
  }
}
export default AdminRepository;
