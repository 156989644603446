import { Button, Slider } from "@mui/material";
import { useEffect, useState } from "react";

import useResponsive from "src/hooks/useResponsive";

const marks = [0, 10, 20, 30, 40, 50].map((value) => ({
  value,
  label: `${value}%`,
}));

const SelectDuration = ({ onChange, initialHours, initialMinutes }) => {
  const isMdUp = useResponsive("up", "md");

  const [additionalTime, setAdditionalTime] = useState(undefined);
  const [newHours, newMinutes] = calculateNewDuration(additionalTime, initialHours, initialMinutes);

  useEffect(() => {
    if (additionalTime !== undefined) {
      onChange(newHours, newMinutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalTime]);

  const needMoreTime = additionalTime !== undefined;
  const setNeedMoreTime = () => setAdditionalTime(0);

  return (
    <div>
      {needMoreTime ? (
        <>
          <p style={{ margin: 0 }}>
            Duration: {newHours} {pluralize("hour", newHours)}{" "}
            {newMinutes > 0 && (
              <>
                {newMinutes} {pluralize("minute", newMinutes)}
              </>
            )}
          </p>
          {additionalTime && additionalTime > 0 ? (
            <p style={{ margin: 0 }}>including {additionalTime}% additional time</p>
          ) : (
            <></>
          )}

          <p>Drag the slider to add additional time</p>
          <div
            style={{
              width: isMdUp ? "275px" : "90%",
              marginLeft: isMdUp ? "0.5rem" : "1rem",
            }}
          >
            <Slider
              aria-label="Temperature"
              value={additionalTime}
              onChange={(_, newValue) => setAdditionalTime(newValue)}
              step={10}
              min={0}
              max={50}
              marks={marks}
              getAriaValueText={(value) => `${value}%`}
              color="primary"
              size="large"
            />
          </div>
        </>
      ) : (
        <>
          <p style={{ marginBottom: "0.5rem" }}>
            Duration: {initialHours} {pluralize("hour", initialHours)}{" "}
            {initialMinutes > 0 && (
              <>
                {initialMinutes} {pluralize("minute", initialMinutes)}
              </>
            )}
          </p>
          <Button variant="outlined" color="inherit" onClick={() => setNeedMoreTime(!needMoreTime)} size="small">
            Need more time?
          </Button>
        </>
      )}
    </div>
  );
};

export default SelectDuration;

const calculateNewDuration = (additionalTime, initialHours, initialMinutes) => {
  const currentTimeInMinutes = initialHours * 60 + initialMinutes;
  const newTimeInMinutes = currentTimeInMinutes * (1 + additionalTime / 100);
  const newHours = Math.floor(newTimeInMinutes / 60);
  const newMinutes = Math.floor(newTimeInMinutes % 60);
  return [newHours, newMinutes];
};

const pluralize = (word, num) => {
  return num === 1 ? word : word + "s";
};
