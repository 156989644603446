import { Button } from "@mui/material";

import CSRFToken from "src/custom_components/CSRFToken";
import { createCheckoutSessionEndpoint } from "src/urls";

export function AuthenticatedProceedToPayment({ isMobile, emailAddress, name, phone, resourcesStr, basketId }) {
  return (
    <form
      action={createCheckoutSessionEndpoint}
      method="POST"
      style={{
        display: isMobile ? "initial" : "contents",
      }}
    >
      <CSRFToken />
      <input type="hidden" name="email_address" value={emailAddress} />
      <input type="hidden" name="name" value={name} />
      <input type="hidden" name="phone" value={phone} />
      <input type="hidden" name="resource_ids" value={resourcesStr} />
      <input type="hidden" name="basket_id" value={basketId} />

      <Button
        sx={{
          mt: 2,
          mb: 2,
        }}
        size="large"
        variant="contained"
        color="inherit"
        type="submit"
        style={{
          backgroundColor: "#009d47",
        }}
      >
        Proceed to Payment
      </Button>
    </form>
  );
}
