const getActualAnswer = (isCalculation, answerUnit, actualAnswerId, possibleAnswers) => {
  if (isCalculation) {
    if (answerUnit === "£") {
      return "£" + actualAnswerId.toString();
    }
    return actualAnswerId.toString() + answerUnit.toString();
  }

  return possibleAnswers.find((possibleAnswer) => possibleAnswer.answerId === actualAnswerId).answerText;
};

export default getActualAnswer;
