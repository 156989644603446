import axios from "axios";

import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";
import CourseModel from "src/models/CourseModel";

interface CourseDto {
  id: number;
  title: string;
  description: string;
  hours: number;
  resource: number | null;
  slug: string;
  tutor: any;
  chapters: any;
  popular_order: number | null;
  included_in_premium: boolean;
}

interface ICourseRepository {
  getCourse(slug: string): Promise<CourseModel>;
  getCourses(): Promise<CourseModel[]>;
}

class CourseRepository implements ICourseRepository {
  async getCourse(slug: string) {
    try {
      const response = await axios.get<CourseDto>(`/api/courses/${slug}/`);
      if (response.status !== 200) throw new Error();
      return mapCourseDtoToCourseModel(response.data);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch course.");
    }
  }

  async getCourses() {
    try {
      const response = await axios.get<CourseDto[]>("/api/courses/");
      if (response.status !== 200) throw new Error();
      const courses = response.data.map((c) => mapCourseDtoToCourseModel(c));
      const sortedCourses = courses.sort((a, b) => a.popularOrder - b.popularOrder);
      return sortedCourses;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch courses.");
    }
  }

  async getCourseLessonProgress(courseId: number) {
    try {
      const response = await axios.get<CourseLessonProgressModel[]>("/api/lesson_progress/get_course_progress/", {
        params: { course_id: courseId },
      });
      if (response.status !== 200) throw new Error();
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch course lesson progress.");
    }
  }
}

export default CourseRepository;

const mapCourseDtoToCourseModel = (courseDto: CourseDto): CourseModel => {
  return {
    id: courseDto.id,
    title: courseDto.title,
    description: courseDto.description,
    hours: courseDto.hours,
    resourceId: courseDto.resource,
    slug: courseDto.slug,
    tutor: courseDto.tutor,
    chapters: courseDto.chapters,
    popularOrder: courseDto.popular_order ?? 10000, // this will ensure those courses without popular_order are placed at the end
    includedInPremium: courseDto.included_in_premium,
  };
};
