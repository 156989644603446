import { Divider, Typography } from "@mui/material";

import CustomAccordion from "src/custom_components/courses/CustomAccordion";
import CustomModal from "src/newComponents/CustomModal";

import ManageUserResources from "./ManageUserResources";

interface User {
  id: number;
  name: string;
  email: string;
  notes: string;
}

interface UpdateUserModalProps {
  user: User;
  handleClose: () => void;
  updateUserNotes: (userId: number, newUserNotes: string) => void;
}

const UpdateUserModal = ({ user, handleClose, updateUserNotes }: UpdateUserModalProps) => {
  const onSuccessCallbackFn = (newUserNotes: string) => {
    updateUserNotes(user.id, newUserNotes);
    handleClose();
  };

  return (
    <CustomModal title="Update User" isOpen={true} handleClose={handleClose} maxWidth="md">
      <UserDetails user={user} />
      <UserNotes notesString={user.notes} />
      <ManageUserResources userId={user.id} userName={user.name} onSuccessCallbackFn={onSuccessCallbackFn} />
    </CustomModal>
  );
};

export default UpdateUserModal;

function UserDetails({ user }: { user: User }) {
  return (
    <p style={{ marginTop: 0, marginBottom: "0.5rem" }}>
      Name: {user.name}
      <br />
      Email: {user.email}
    </p>
  );
}

function UserNotes({ notesString }: { notesString: string }) {
  if (notesString.length === 0) return <p>No notes.</p>;

  // reverse, so that the latest note is shown first
  const notes = notesString
    .split("\r\nEND_OF_NOTE\r\n")
    .filter((note) => note.length > 0)
    .reverse();

  return (
    <CustomAccordion
      removeMargin={true}
      title={<Typography variant="h6">Notes (latest first)</Typography>}
      isDisabled={false}
      isLesson={false}
      content={
        <>
          {notes.map((note, idx) => {
            return (
              <>
                <p key={idx} style={{ marginTop: 0, marginBottom: 0 }}>
                  <UserNote note={note} />
                </p>
                <Divider textAlign="left" sx={{ my: 2.5 }}>
                  END OF NOTE
                </Divider>
              </>
            );
          })}
        </>
      }
    />
  );
}

function UserNote({ note }: { note: string }) {
  const noteLines = note.split("\n");
  return (
    <>
      {noteLines.map((notePart, idx) => {
        const isLastNotePart = idx === noteLines.length - 1;
        const isEmpty = notePart.trim().length === 0;
        if (isEmpty && isLastNotePart) return null;
        return (
          <span key={idx}>
            {notePart}
            <br />
          </span>
        );
      })}
    </>
  );
}
