import { Button, FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import DateService, { DateType } from "src/utils/dateService";

interface AccessDateSelectorProps {
  onChange: (access: DateType) => void;
  date: DateType;
  label: string;
}

const AccessDateSelector = ({ onChange, date, label }: AccessDateSelectorProps) => {
  return (
    <div>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={label}
            value={date}
            onChange={(newDate) => {
              if (newDate === null) return;
              onChange(newDate);
            }}
            format="DD/MM/YY hh:mm:ss A"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
          />
        </LocalizationProvider>
        <div style={{ display: "flex", gap: "8px", marginTop: "0.75rem" }}>
          <Button
            color="warning"
            size="small"
            variant="contained"
            onClick={() => {
              onChange(DateService.newDate());
            }}
            style={{ width: "90px" }}
          >
            Now
          </Button>

          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.add(-1, "day"));
            }}
            style={{ width: "90px" }}
          >
            -1 Day
          </Button>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.add(1, "day"));
            }}
            style={{ width: "90px" }}
          >
            +1 Day
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.add(-1, "week"));
            }}
            style={{ width: "90px" }}
          >
            -1 Week
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.add(1, "week"));
            }}
            style={{ width: "90px" }}
          >
            +1 Week
          </Button>
          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.add(-13, "week"));
            }}
            style={{ width: "90px" }}
          >
            -13 Weeks
          </Button>
          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.add(13, "week"));
            }}
            style={{ width: "90px" }}
          >
            +13 Weeks
          </Button>
          <Button
            color="inherit"
            size="small"
            variant="contained"
            onClick={() => {
              onChange(date.endOf("day"));
            }}
            style={{ width: "90px" }}
          >
            Midnight
          </Button>
        </div>
      </FormControl>
    </div>
  );
};

export default AccessDateSelector;
