import { useLocation } from "react-router-dom";

import NavItem from "./NavItem";
import { NavSectionVertical } from "../../../nav-section";

export default function NavList({ item }) {
  const { pathname } = useLocation();
  const { path, children } = item;

  return (
    <>
      <NavItem item={item} active={pathname === path} />

      {!!children && <NavSectionVertical data={children} />}
    </>
  );
}
