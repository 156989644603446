import { Stack } from "@mui/material";

import { FancyPageSubtitle, FancyPageTitle } from "src/pages/landing/components/QuestionBankTopSPRC";

export default function WorkshopsTopSection() {
  return (
    <Stack
      sx={{
        alignItems: "center",
        mt: {
          md: 4,
          lg: 2,
        },
      }}
    >
      <FancyPageTitle titles={[`Explore your`, ` Pharmacy Career Options `, `with our `, `Interactive Workshops`]} />
      <FancyPageSubtitle
        subtitle={
          "Participate in engaging virtual workshops delivered by industry experts, where you can explore career opportunities and entry requirements."
        }
      />
    </Stack>
  );
}
