import { useState } from "react";

import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";
import DateService from "src/utils/dateService";

import AccessDateSelector from "../manage_access_helpers/AccessDateSelector";
import GiveAccess from "../manage_access_helpers/GiveAccess";

interface ManageDSPAccessProps {
  selectedAction: "give" | "edit" | "remove";
  allResources: ResourceModel[];
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

const ManageDSPAccess = ({
  selectedAction,
  allResources,
  userId,
  userName,
  onSuccessCallbackFn,
}: ManageDSPAccessProps) => {
  const dspResources = allResources.filter(
    (resource) => resource.type === ResourceModelType.designatedSupervisorProgramme
  );

  if (dspResources.length !== 1) {
    throw new Error("Invalid number of designated supervisor programme resources found");
  }

  const dsp = dspResources[0];

  if (selectedAction === "give") {
    if (dsp.access !== null) {
      return <p>User has access or their access has expired, select "Edit Access" or "Remove Access" instead.</p>;
    }
    return <AddAccess userId={userId} userName={userName} onSuccessCallbackFn={onSuccessCallbackFn} dsp={dsp} />;
  }

  if (selectedAction === "edit") {
    if (dsp.access === null) {
      return <p>User has no access to designated supervisor programme, select "Give Access" instead.</p>;
    }

    return <EditAccess userId={userId} userName={userName} onSuccessCallbackFn={onSuccessCallbackFn} dsp={dsp} />;
  }

  if (selectedAction === "remove") {
    if (dsp.access === null) {
      return <p>User has no access to designated supervisor programme, select "Give Access" instead.</p>;
    }

    return (
      <div>
        <p>By proceeding you will remove user's access to designated supervisor programme.</p>

        <GiveAccess
          text="Permanently remove access to designated supervisor programme"
          userId={userId}
          userName={userName}
          onSuccessCallbackFn={onSuccessCallbackFn}
          accessInstructions={[
            {
              resource: dsp,
              crudAction: "delete",
              accessStart: null,
              accessEnd: null,
            },
          ]}
        />
      </div>
    );
  }

  throw new Error("Invalid action");
};

export default ManageDSPAccess;

interface AddAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  dsp: ResourceModel;
}

const AddAccess = ({ userId, userName, onSuccessCallbackFn, dsp }: AddAccessProps) => {
  const [startDate, setStartDate] = useState(DateService.newDate());
  const [endDate, setEndDate] = useState(DateService.newDate());
  return (
    <div>
      <p>By proceeding you will give user access to designated supervisor programme.</p>

      <AccessDateSelector label="Access Start" date={startDate} onChange={setStartDate} />
      <br />
      <AccessDateSelector label="Access End" date={endDate} onChange={setEndDate} />
      <br />

      <GiveAccess
        text="Give access to designated supervisor programme"
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: dsp,
            crudAction: "create",
            accessStart: startDate,
            accessEnd: endDate,
          },
        ]}
      />
    </div>
  );
};

interface EditAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  dsp: ResourceModel;
}

const EditAccess = ({ userId, userName, onSuccessCallbackFn, dsp }: EditAccessProps) => {
  const [startDate, setStartDate] = useState(
    dsp.access ? DateService.parseServerDate(dsp.access.accessBegins?.toISOString()) : null
  );
  const [endDate, setEndDate] = useState(
    dsp.access ? DateService.parseServerDate(dsp.access.accessEnds?.toISOString()) : null
  );

  if (dsp.access === null || startDate === null || endDate === null) {
    return <></>;
  }

  return (
    <div>
      <p>By proceeding you will edit user access to designated supervisor programme.</p>

      <AccessDateSelector label="Access Start" date={startDate} onChange={setStartDate} />
      <br />
      <AccessDateSelector label="Access End" date={endDate} onChange={setEndDate} />
      <br />

      <GiveAccess
        text="Update access to designated supervisor programme"
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: dsp,
            crudAction: "update",
            accessStart: startDate,
            accessEnd: endDate,
          },
        ]}
      />
    </div>
  );
};
