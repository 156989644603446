import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

export const StyledLink = styled((props) => <Link component={RouterLink} {...props} />)(({ theme }) => {
  return {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  };
});
