import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import ConfigurationModel from "src/models/ConfigurationModel";
import ConfigurationRepository from "src/repository/configurationRepository";
import QuestionSetRepostiory from "src/repository/questionSetRepository";

import { logoutEndpoint } from "../urls";

interface AppState {
  userId: number | null;
  emailAddress: string;
  name: string;
  phone: string;
  isSuperuser: boolean;
  isAuthenticated: boolean;
  justLoggedIn: boolean;
  configurations: ConfigurationModel[] | undefined;
}

interface AppContextType extends AppState {
  updateUserDetails: (
    id: number,
    emailAddress: string,
    name: string,
    phone: string,
    isSuperuser: boolean,
    loggedInViaSessionCookies: boolean
  ) => void;
  logout: () => void;
  clearAuthState: () => void;
}

export const AppContext = React.createContext({} as AppContextType);

const defaultAppState = () => {
  const defaultState: AppState = {
    userId: null,
    emailAddress: "",
    name: "",
    phone: "",
    isSuperuser: false,
    isAuthenticated: false,
    justLoggedIn: false,
    configurations: undefined,
  };
  return defaultState;
};

export const AppContextProvider = (props: React.PropsWithChildren<Record<string, never>>) => {
  const [state, setState] = useState<AppState>(defaultAppState());

  const navigate = useNavigate();
  const isLightMode = useIsLightMode();

  useEffect(() => {
    loadConfigurations();
    if (state.isAuthenticated) {
      const questionSetRepository = new QuestionSetRepostiory();
      questionSetRepository.attemptToSaveMockPaperAttemptAgain(isLightMode);
    }
  }, [state.isAuthenticated]);

  const loadConfigurations = () => {
    const configurationRepository = new ConfigurationRepository();
    configurationRepository
      .getConfigurations()
      .then((configs) =>
        setState((prevState) => ({
          ...prevState,
          configurations: configs,
        }))
      )
      .catch(() => errorToast("Failed to fetch configurations.", isLightMode));
  };

  const updateUserDetails = (
    id: number,
    emailAddress: string,
    name: string,
    phone: string,
    isSuperuser: boolean,
    loggedInViaSessionCookies: boolean
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        userId: id,
        isSuperuser: isSuperuser,
        emailAddress,
        name,
        phone,
        isAuthenticated: true,
        justLoggedIn: !loggedInViaSessionCookies,
      };
    });
  };

  const clearAuthState = () => {
    setState(defaultAppState());
  };

  const logout = () => {
    axios
      .post(logoutEndpoint)
      .then(() => {
        clearAuthState();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const context = {
    ...state,
    updateUserDetails,
    logout,
    clearAuthState,
  };

  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
};
