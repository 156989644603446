import { Typography } from "@mui/material";
import { useLocalStorage, usePrevious } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { DARK_MODE_TOAST_STYLE, LIGHT_MODE_TOAST_STYLE } from "src/App";
import QuestionSetRepostiory from "src/repository/questionSetRepository";
import DateService from "src/utils/dateService";
import { TEN_SECONDS } from "src/utils/duration";

import MPQuizMenu from "./MPQuizMenu";
import QBQuestion, { paleOrange } from "./qb_question/QBQuestion";
import { getCurrentQuestionIndex, getExpiryTimestamp, mapQuestionDtoToQuestionProps } from "./QBQuiz";
import QBReviewAndFeedbackMenu from "./QBReviewAndFeedbackMenu";
import QBTimer from "./QBTimer";
import {
  QUIZ_NOT_STARTED,
  QUIZ_IN_PROGRESS_ON_QUESTION_PAGE,
  QUIZ_IN_PROGRESS_ON_REVIEW_PAGE,
  QUIZ_FINISHED_FEEDBACK_PAGE,
  QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME,
  QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE,
  QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME,
} from "./QuizStates";
import { confirmToast } from "./toast";
import { errorToast, infoToast } from "./toast/toast";
import { formatAttemptTime } from "./utils/formatAttemptTime";
import { getMPQuizDefaultState } from "./utils/getDefaultMPQuizState";

const REVIEW_PAGE_SUFFIX = "/review";
const FEEDBACK_PAGE_SUFFIX = "/feedback";

const MPQuiz = ({ getQuestions, isLightMode, questionSet }) => {
  const { title, duration: initialDuration, numQuestions } = questionSet;

  const questionSetRepository = new QuestionSetRepostiory();
  const navigate = useNavigate();
  const params = useParams();
  const currentQuestionIndex = getCurrentQuestionIndex(params);

  const questionSetAttempt = useLocalStorage("mp_questionSetAttempt", null)[0];
  const ds = getMPQuizDefaultState(questionSetAttempt, initialDuration);
  const isViewingPastAttempt = ds.isViewingAttempt;

  const [quizMetaData, setQuizMetaData] = useLocalStorage("mp_quizMetaData", ds.quizMetaData);

  const [quizState, setQuizState] = useLocalStorage("mp_quizState", ds.quizState);
  const [questions, setQuestions] = useLocalStorage("mp_questions", ds.questions);
  const [timerHidden, setHideTimer] = useLocalStorage("mp_timerHidden", ds.hideTimer);
  const [quizExpiryString, setQuizExpiry] = useLocalStorage("mp_quizExpiry", ds.quizExpiry);
  const quizExpiry = quizExpiryString ? new Date(quizExpiryString) : null;
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const currentUrl = window.location.href;
  const previousUrl = usePrevious(currentUrl);

  useEffect(() => {
    // no need to check if user is viewing past attempt
    if (isViewingPastAttempt) return;

    const currentUrlContainsFeedback = currentUrl.includes(FEEDBACK_PAGE_SUFFIX);
    const reachedFeedbackStateNaturally = quizState === QUIZ_FINISHED_FEEDBACK_PAGE;

    // if our url shows feedback and we're already in the feedback state
    // the user must have refreshed the browser so don't show "out of time" message
    // and no need to enter feedback mode again
    const currentUrlContainsFeedbackAndFeedbackState = currentUrlContainsFeedback && reachedFeedbackStateNaturally;

    if (currentUrlContainsFeedbackAndFeedbackState) return;

    if (quizExpiry) {
      const now = new Date();
      if (now > quizExpiry) {
        infoToast(
          "You ran out of time! Your attempt has been submitted automatically. Check the feedback below for more information.",
          isLightMode
        );
        enterFeedbackMode({ outOfTime: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (previousUrl === currentUrl) return;
    if (previousUrl === null) return;
    if (currentUrl === null) return;

    const previousUrlContainsNumber = previousUrl.split("attempt-mock-paper")[1].match(/\d+/g);
    const previousUrlContainsReview = previousUrl.includes(REVIEW_PAGE_SUFFIX);
    const previousUrlContainsFeedback = previousUrl.includes(FEEDBACK_PAGE_SUFFIX);

    const currentUrlContainsReview = currentUrl.includes(REVIEW_PAGE_SUFFIX);
    const currentUrlContainsFeedback = currentUrl.includes(FEEDBACK_PAGE_SUFFIX);
    const currentUrlIsQuestion = !currentUrlContainsReview && !currentUrlContainsFeedback;

    // went from Review page BACK to question page
    if (previousUrlContainsReview && currentUrlIsQuestion) {
      if (quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE) {
        setQuizState(QUIZ_IN_PROGRESS_ON_QUESTION_PAGE);
        return;
      }
    }

    // went from Feedback page BACK to question page
    if (previousUrlContainsFeedback && currentUrlIsQuestion) {
      if (quizState === QUIZ_FINISHED_FEEDBACK_PAGE) {
        setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE);
        return;
      } else if (quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME) {
        setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME);
        return;
      }
    }

    // went from question page to Review page
    if (previousUrlContainsNumber && currentUrlContainsReview) {
      if (quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE) {
        setQuizState(QUIZ_IN_PROGRESS_ON_REVIEW_PAGE);
        return;
      }
    }

    // went from question page to Feedback page
    if (previousUrlContainsNumber && currentUrlContainsFeedback) {
      if (quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE) {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE);
        return;
      } else if (quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME) {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME);
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  const setCurrentQuestionIndex = (idx) => {
    navigate(`/attempt-mock-paper/${idx}`);
  };

  // when the quiz starts, retrieve data, set questions and
  // current question index
  const onQuizStart = async (duration) => {
    setLoadingQuestions(true);
    setQuizMetaData({
      duration,
      startTime: DateService.newDate(),
    });
    setQuizExpiry(getExpiryTimestamp(duration));

    const questionsFromApi = await getQuestions(duration);

    if (questionsFromApi === null) {
      setLoadingQuestions(false);
      return;
    }

    const questions = questionsFromApi.map((q, idx) => {
      const questionNumber = idx + 1;
      const totalNumberOfQuestions = questionsFromApi.length;

      return mapQuestionDtoToQuestionProps(q, questionNumber, totalNumberOfQuestions);
    });

    setLoadingQuestions(false);
    setQuestions(questions);
    setQuizState(QUIZ_IN_PROGRESS_ON_QUESTION_PAGE);
    setCurrentQuestionIndex(0);
    window.scrollTo(0, 0);
  };

  // When the quiz timer has expired, forcefully end!
  const onQuizExpire = () => {
    toast(
      "You ran out of time! Your attempt has been submitted automatically. Check the feedback below for more information.",
      {
        icon: "⏳",
        duration: TEN_SECONDS,
        style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
      }
    );

    enterFeedbackMode({ outOfTime: true });
  };

  const goToQuestion = (idx) => {
    window.scrollTo(0, 0);
    setCurrentQuestionIndex(idx);
  };

  const setAnswerId = (questionIndex, newAnswerId) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].answerId = newAnswerId;
      return newQuestions;
    });
  };

  const toggleQuestionFlag = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].isFlagged = !newQuestions[questionIndex].isFlagged;
      return newQuestions;
    });
  };

  const nextQuestion = () => {
    const wasOnLastQuestion = currentQuestionIndex === questions.length - 1;
    if (wasOnLastQuestion) {
      enterFeedbackMode({ outOfTime: false });
      return;
    }
    goToQuestion(currentQuestionIndex + 1);
  };

  const backQuestion = () => {
    const wasOnFirstQuestion = currentQuestionIndex === 0;
    if (wasOnFirstQuestion) return;
    goToQuestion(currentQuestionIndex - 1);
  };

  const enterReviewMode = () => {
    window.scrollTo(0, 0);
    setQuizState(QUIZ_IN_PROGRESS_ON_REVIEW_PAGE);

    navigate(`/attempt-mock-paper${REVIEW_PAGE_SUFFIX}`);
  };

  const enterFeedbackMode = ({ outOfTime }) => {
    const actuallyEnterFbMode = () => {
      if (outOfTime) {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME);
      } else {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE);
      }
      window.scrollTo(0, 0);

      // startTime from quizMetaData is a string because it's stored in local storage.
      const startTime = DateService.parseServerDate(quizMetaData.startTime);
      const endTime = DateService.newDate();

      const newQuizMetaData = {
        ...quizMetaData,
        numberOfQuestionsAnsweredCorrectly: questions.filter((q) => q.answerId === q.question.actualAnswerId).length,
        timeTakenInSeconds: DateService.newDate().diff(startTime, "second"),
      };

      setQuizMetaData(newQuizMetaData);
      const requestData = questionSetRepository.generateRequestData(
        questionSet,
        startTime,
        endTime,
        quizMetaData.duration,
        JSON.stringify(questions)
      );

      // if success, proceed silently. if error, try again later via different
      // repository method
      questionSetRepository.saveAttempt(requestData).catch(() => {
        errorToast(
          "Error saving Mock Paper attempt. Please refresh the page. If the issue persists, contact support.",
          isLightMode
        );
        localStorage.setItem("mp_failedToSaveAttempt", JSON.stringify(requestData));
      });

      // set expiry to yesterday, so UI considers quiz as expired on mock paper page
      const yesterdayDate = new Date();
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      setQuizExpiry(yesterdayDate);
      navigate(`/attempt-mock-paper${FEEDBACK_PAGE_SUFFIX}`);
    };

    const flaggedQuestions = questions.filter((q) => q.isFlagged);
    const numFlaggedQuestions = flaggedQuestions.length;
    const hasFlaggedQuestions = numFlaggedQuestions > 0;
    if (outOfTime === false && hasFlaggedQuestions) {
      const plural = numFlaggedQuestions > 1 ? "s" : "";
      confirmToast({
        message: `You have ${numFlaggedQuestions} flagged question${plural}. Are you sure you want to submit?`,
        isLightMode: isLightMode,
        onConfirm: actuallyEnterFbMode,
        confirmText: "Submit",
        cancelText: "Review Answers",
      });
    } else {
      actuallyEnterFbMode();
    }
  };

  const showTimer =
    !isViewingPastAttempt &&
    (quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE || quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE);

  const showQuizSetupMenu = quizState === QUIZ_NOT_STARTED;

  const showQuestion = quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE;

  const showReviewMenu = quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE;

  const showFeedbackMenu =
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE || quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME;

  const showQuestionWithFeedback =
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  const shouldBeAQuestion =
    quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  if (currentQuestionIndex === null && shouldBeAQuestion) {
    return (
      <div>
        <Typography>
          Please go back to the previous page and use the buttons within the quiz to navigate between pages.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div style={{ position: "relative" }}>
        {showTimer && (
          <div style={{ float: "right" }} data-cy="MPQuiz_Timer">
            <QBTimer
              expiryTimestamp={quizExpiry}
              totalDurationInMinutes={quizMetaData.duration}
              onExpire={onQuizExpire}
              isLightMode={isLightMode}
              timerHidden={timerHidden}
              toggleHideTimer={() => setHideTimer(!timerHidden)}
            />
          </div>
        )}

        <Title title={title} quizState={quizState} />
      </div>

      {showQuizSetupMenu && (
        <div>
          <MPQuizMenu
            onStart={onQuizStart}
            loadingQuestions={loadingQuestions}
            isLightMode={isLightMode}
            numQuestions={numQuestions}
            initialDuration={initialDuration}
            mockPaperOrder={questionSet.mockPaperOrder}
          />
        </div>
      )}

      {showQuestion && (
        <div>
          <QBQuestion
            obj={{ ...questions[currentQuestionIndex] }}
            quizState={quizState}
            nextQuestion={nextQuestion}
            backQuestion={backQuestion}
            setAnswerId={(newAnswerId) => {
              setAnswerId(currentQuestionIndex, newAnswerId);
            }}
            toggleFlag={() => {
              toggleQuestionFlag(currentQuestionIndex);
            }}
            enterReviewMode={enterReviewMode}
            returnToFeedback={() => {}}
          />
        </div>
      )}

      {showReviewMenu && (
        <QBReviewAndFeedbackMenu
          questions={questions}
          goToQuestion={(idx) => {
            goToQuestion(idx);
            setQuizState(QUIZ_IN_PROGRESS_ON_QUESTION_PAGE);
          }}
          toggleQuestionFlag={toggleQuestionFlag}
          submitQuiz={() => {
            enterFeedbackMode({ outOfTime: false });
          }}
          isFeedbackMenu={false}
        />
      )}

      {showFeedbackMenu && (
        <div>
          <Feedback
            quizMetaData={quizMetaData}
            ranOutOfTime={quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME}
            numQuestions={questions.length}
          />

          <QBReviewAndFeedbackMenu
            questions={questions}
            goToQuestion={(idx) => {
              goToQuestion(idx);
              if (quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME) {
                setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME);
              } else {
                setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE);
              }
            }}
            toggleQuestionFlag={() => {}}
            submitQuiz={() => {}}
            isFeedbackMenu={true}
          />
        </div>
      )}

      {showQuestionWithFeedback && (
        <div style={{ marginTop: "28px" }}>
          <QBQuestion
            obj={{ ...questions[currentQuestionIndex] }}
            quizState={quizState}
            nextQuestion={() => {}}
            backQuestion={() => {}}
            setAnswerId={() => {}}
            toggleFlag={() => {}}
            enterReviewMode={() => {}}
            returnToFeedback={() => {
              if (quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME) {
                setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME);
              } else {
                setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE);
              }
              navigate(`/attempt-mock-paper/feedback`);
            }}
          />
        </div>
      )}

      <br />
    </div>
  );
};

export default MPQuiz;

const Title = ({ title, quizState }) => {
  const quizInProgress = quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE;
  const inReviewMode = quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE;
  const inFeedbackMode =
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  return (
    <div style={{ marginBottom: "1.4rem" }}>
      <Typography variant="h3" data-cy="MPQuiz_Title">
        {quizState === QUIZ_NOT_STARTED && `${title}: Setup`}
        {quizInProgress && `${title}: In Progress`}
        {inReviewMode && `${title}: Review`}
        {inFeedbackMode && `${title}: Feedback`}
      </Typography>
    </div>
  );
};

const Feedback = ({ quizMetaData, ranOutOfTime, numQuestions }) => {
  const { numberOfQuestionsAnsweredCorrectly, timeTakenInSeconds } = quizMetaData;

  const percentage = ((numberOfQuestionsAnsweredCorrectly / numQuestions) * 100).toFixed(0);

  const timeTaken = formatAttemptTime(timeTakenInSeconds);

  return (
    <div
      style={{
        marginTop: "0.8rem",
        backgroundColor: paleOrange,
        padding: "1rem",
      }}
    >
      {ranOutOfTime && (
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          You ran out of time. Quiz was submitted automatically.
        </Typography>
      )}

      <Typography sx={{ fontWeight: "bold" }}>
        You answered {numberOfQuestionsAnsweredCorrectly}/{numQuestions} questions correctly. You scored: {percentage}%.
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>The pass mark for this mock paper is 70%.</Typography>
      <Typography>Time taken: {timeTaken}</Typography>
    </div>
  );
};
