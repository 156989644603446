import { Grid, Stack, Typography, Avatar } from "@mui/material";
import { useState } from "react";

import Image from "src/components/image/Image";
import RenderTextAreaContent from "src/custom_components/RenderTextAreaContent";
import TutorModel from "src/models/TutorModel";

const RenderTutor = ({ tutor, shortBioWithLearnMore }: { tutor: TutorModel; shortBioWithLearnMore: boolean }) => {
  const [numCharactersToShow, setNumCharactersToShow] = useState(175);
  if (shortBioWithLearnMore) {
    return (
      <>
        <Typography variant="h4" sx={{ mb: 1.75, mt: 1.75 }}>
          Tutor
        </Typography>
        <Stack direction="row" alignItems="center" sx={{ mr: 2.5, mb: 2 }}>
          <Avatar src={tutor.profile_image ?? undefined} />

          <Stack direction="column" sx={{ ml: 1 }}>
            <Typography variant="body2">{tutor.name}</Typography>
            <Typography variant="body2">{tutor.role}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="body1">
            {tutor.bio.length > numCharactersToShow ? tutor.bio.slice(0, numCharactersToShow) + "... " : tutor.bio}
            {tutor.bio.length > numCharactersToShow && (
              <span
                className="faqPageLink"
                onClick={() => setNumCharactersToShow(tutor.bio.length)}
                style={{ cursor: "pointer" }}
              >
                learn more
              </span>
            )}
          </Typography>
        </Stack>
      </>
    );
  }
  return (
    <Grid item xs={12} sm={4} sx={{ mb: 2.5 }}>
      <Typography variant="h3" sx={{ mb: 3 }}>
        Tutor
      </Typography>

      <Stack direction={"row"} spacing={3} sx={{ verticalAlign: "center" }} alignItems={"center"}>
        <Stack>
          {tutor.profile_image ? (
            <Image
              src={tutor.profile_image}
              alt={tutor.name}
              sx={{
                objectFit: "cover",
                width: { xs: 100, sm: 80, md: 100 },
                height: { xs: 100, sm: 80, md: 100 },
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: { xs: 95, sm: 75, md: 95 },
                height: { xs: 95, sm: 75, md: 95 },
              }}
            />
          )}
        </Stack>
        <Stack>
          <Typography variant="h6">{tutor.name}</Typography>
          <Typography variant="body1">{tutor.role}</Typography>
        </Stack>
      </Stack>
      <div style={{ marginTop: "1rem" }}>
        <RenderTextAreaContent content={tutor.bio} />
      </div>
    </Grid>
  );
};

export default RenderTutor;
