import { Button } from "@mui/material";
import { useContext } from "react";

import { confirmToast, errorToast, successToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import ResourceModel from "src/models/ResourceModel";
import AdminRepository from "src/repository/adminRepository";
import DateService, { DateType } from "src/utils/dateService";

export interface AccessInstruction {
  resource: ResourceModel;
  crudAction: "create" | "update" | "delete";
  accessStart: DateType | null;
  accessEnd: DateType | null;
}

interface GiveAccessProps {
  text: string;
  userId: number;
  userName: string;
  accessInstructions: AccessInstruction[];
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

const GiveAccess = ({ text, userId, userName, accessInstructions, onSuccessCallbackFn }: GiveAccessProps) => {
  const isLightMode = useIsLightMode();
  const { name: adminName } = useContext(AppContext);
  const confirmText = mapAccessInstructionsToText(userName, text, accessInstructions, adminName);

  const adminRepository = new AdminRepository();

  const execute = () => {
    adminRepository
      .executeAccessInstructions(userId, accessInstructions, confirmText)
      .then((newUserNotes) => {
        successToast("Success!");
        onSuccessCallbackFn(newUserNotes);
      })
      .catch(() => {
        errorToast("Failed to modify user resource accesses.");
      });
  };

  const executeInstruction = () => {
    const invalidInstructions = accessInstructions.filter(
      (instruction) =>
        instruction.accessStart !== null &&
        instruction.accessEnd !== null &&
        instruction.accessStart.isAfter(instruction.accessEnd)
    );
    if (invalidInstructions.length > 0) {
      errorToast("Access start date cannot be after access end date");
      return;
    }
    confirmToast({
      message:
        "Are you sure you want to do this? The following will be executed and added to user's notes: \n\n" +
        confirmText,
      isLightMode: isLightMode,
      onConfirm: execute,
      confirmText: "Execute",
    });
  };

  return (
    <div>
      <Button color="info" variant="contained" onClick={executeInstruction}>
        {text}
      </Button>
    </div>
  );
};

export default GiveAccess;

const mapAccessInstructionsToText = (
  userName: string,
  summaryText: string,
  accessInstructions: AccessInstruction[],
  adminName: string
) => {
  let text = "";
  text += "Admin: " + adminName + "\n";
  text += "Date: " + DateService.formatOne(DateService.newDate()) + "\n";
  text += "Summary: " + summaryText + "\n";
  text += "User: " + userName + "\n\n";
  text += "Detailed access instructions: \n";
  accessInstructions.forEach((accessInstruction) => {
    text += mapAccessInstructionToText(accessInstruction);
  });

  return text;
};

const mapAccessInstructionToText = (accessInstruction: AccessInstruction) => {
  let text = "";
  text += "Resource: " + accessInstruction.resource.title + ", ";
  text += "action: " + accessInstruction.crudAction;
  if (accessInstruction.crudAction === "delete") {
    return text;
  }
  text +=
    ", access start: " +
    (accessInstruction.accessStart ? DateService.formatOne(accessInstruction.accessStart) : "None");
  text +=
    ", access end: " +
    (accessInstruction.accessEnd ? DateService.formatOne(accessInstruction.accessEnd) : "None") +
    "\n";

  return text;
};
