import { Box, Dialog, IconButton } from "@mui/material";

import Iconify from "src/components/icon/Iconify";
import { getVideoLockDetails } from "src/config/requirement/requirement";
import NewPlayer from "src/custom_components/NewPlayer";
import RenderTextAreaContent from "src/custom_components/RenderTextAreaContent";

export default function LessonsDialog({ open, onClose, selectedLesson, updateLessonProgress }) {
  const { videoUrl } = getVideoLockDetails(selectedLesson.accessible_video_url);
  const hasVideo = videoUrl && videoUrl?.length > 0;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      sx={{
        width: { xs: 1, sm: 0.9, md: 0.7, lg: 0.6 },
      }}
      onClose={onClose}
      style={{ margin: "auto" }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            top: 16,
            left: 16,
            zIndex: 9,
            position: "absolute",
          }}
        >
          <Iconify width={24} icon="carbon:close" />
        </IconButton>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {hasVideo ? (
            <NewPlayer
              videoUrl={videoUrl}
              videoTitle={selectedLesson.title}
              lessonId={selectedLesson.id}
              saveProgress={true}
              updateLessonProgress={updateLessonProgress}
            />
          ) : (
            "No video found"
          )}
        </Box>
        <div style={{ marginBottom: "0.5rem", padding: "0 1rem 1rem 1rem" }}>
          <RenderTextAreaContent content={selectedLesson.description} />
        </div>
      </Box>
    </Dialog>
  );
}
