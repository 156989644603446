import { Typography } from "@mui/material";

import ConfigurationModel from "src/models/ConfigurationModel";
import NoAccessToFTP from "src/newComponents/NoAccessToFTP";

import RegisterForSessions from "./RegisterForSessions";

interface LiveOnlineStudySessionsTopSectionProps {
  userHasAccess: boolean;
  configurations: ConfigurationModel[];
}

const LiveOnlineStudySessionsTopSection = ({
  userHasAccess,
  configurations,
}: LiveOnlineStudySessionsTopSectionProps) => {
  return (
    <>
      <Typography sx={{ margin: "1rem 0" }}>
        With over 50 study sessions to engage with our tutors for interactive learning throughout the Foundation
        Pharmacist Training Year. Our live study sessions cover key topics including exam revision courses, case
        studies, Q&A sessions, motivation and more.
      </Typography>
      <Typography sx={{ margin: "1rem 0" }}>
        Can't attend live or enrolling later? No problem! You'll have access to all the live study sessions as recorded
        videos to watch later at your convenience.
      </Typography>

      <Typography sx={{ margin: "1rem 0" }}>
        Select Register for Live Sessions to receive Zoom links on the day to join us live.
      </Typography>

      {userHasAccess && <RegisterForSessions configurations={configurations} />}
      {!userHasAccess && <NoAccessToFTP accessToText="Live Online Study Sessions" />}
    </>
  );
};

export default LiveOnlineStudySessionsTopSection;
