import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment, TextField, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

const AweberModal = () => {
  const isLightMode = useIsLightMode();

  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleClickOpen = () => {
    if (email.length === 0) {
      errorToast("Please enter your email address.", isLightMode);
      return;
    }
    if (email.includes("@") === false) {
      errorToast("Please enter a valid email address.", isLightMode);
      return;
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TextField
        style={{ maxWidth: "450px" }}
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        hiddenLabel
        placeholder="Email address"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button variant="contained" color="inherit" size="large" onClick={handleClickOpen}>
                Access
              </Button>
            </InputAdornment>
          ),
          sx: { pr: 0.5 },
        }}
      />

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ paddingRight: "2.75rem" }}>
          <span>Enter your name to access FREE GPhC Exam Questions</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0, marginTop: "1rem", marginBottom: "2rem" }}>
          <form method="post" acceptCharset="UTF-8" action="https://www.aweber.com/scripts/addlead.pl">
            <div>
              <input type="hidden" name="meta_web_form_id" value="868019413" />
              <input type="hidden" name="meta_split_id" value="" />
              <input type="hidden" name="listname" value="awlist6009892" />
              <input
                type="hidden"
                name="redirect"
                value="https://www.aweber.com/thankyou.htm?m=default"
                id="redirect_3feb59c53f69a7487c709a6b8d520679"
              />

              <input type="hidden" name="meta_adtracking" value="New_Website_Landing_Page" />
              <input type="hidden" name="meta_message" value="1" />
              <input type="hidden" name="meta_required" value="name,email" />

              <input type="hidden" name="meta_tooltip" value="" />
            </div>

            <TextField
              name="name"
              sx={{
                minWidth: { xs: "80vw", sm: "400px", md: "500px" },
                maxWidth: { xs: "450px", md: "600px" },
              }}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              hiddenLabel
              placeholder="Name"
              inputRef={(input) => input && input.focus()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="inherit"
                      size="large"
                      type="submit"
                      name="submit"
                      onClick={handleClose}
                      disabled={name.length === 0}
                    >
                      Access
                    </Button>
                  </InputAdornment>
                ),
                sx: { pr: 0.5 },
              }}
            />

            <input type="text" name="email" value={email} readOnly={true} tabIndex="501" style={{ display: "none" }} />
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AweberModal;
