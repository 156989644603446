import { alpha, styled } from "@mui/material/styles";

import { bgGradient } from "src/utils/cssStyles";

const BackgroundGradient = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: "/assets/background/overlay_1.jpg",
  }),
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 2),
}));

export { BackgroundGradient };
