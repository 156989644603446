import { Grid, Typography, Button, Divider } from "@mui/material";
import React, { Suspense } from "react";

import Iconify from "src/components/icon/Iconify";
import PageTitle from "src/custom_components/PageTitle";
import useResponsive from "src/hooks/useResponsive";
import NumbersSPC from "src/newComponents/NumbersSPC";

const VidstackPlayer = React.lazy(() => import("src/newComponents/VidstackPlayer"));

const WhoWeAreSPC = () => {
  const isMdUp = useResponsive("up", "md");

  const renderVisionVideo = (isMdUp) => {
    return (
      <Grid xs={12} md={5} item={true}>
        {!isMdUp && (
          <>
            <br />
            <br />
          </>
        )}

        <Suspense fallback={<div>Loading Vision Video...</div>}>
          <VidstackPlayer
            videoTitle="Our Vision"
            videoUrl="https://vz-9f3714c2-b23.b-cdn.net/71fca40e-1432-4516-b608-a767c71b5e89/playlist.m3u8"
            poster="https://vz-9f3714c2-b23.b-cdn.net/71fca40e-1432-4516-b608-a767c71b5e89/thumbnail_035c367d.jpg"
            posterAlt="Our vision video"
            autoPlay={false}
            crossOrigin={true}
          />
        </Suspense>
      </Grid>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        {isMdUp && renderVisionVideo(isMdUp)}
        <Grid
          xs={12}
          md={6}
          item={true}
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <PageTitle withBottomMargin title="Who We Are?" />

          <Typography sx={{ mt: 3, mb: 5, color: "text.secondary" }}>
            Pre-Reg Shortcuts is a reputable Pharmacy training provider with the ultimate all inclusive foundation year
            programme. Founded by Pharmacists Marvin Munzu and Umar Majid, we understand the transformative power of
            Pharmacy education.
            <br />
            <br />
            Combining our expertise to create an online training community, where we use interactive webinars to deliver
            strategic courses with cutting-edge knowledge to shape the Pharmacists of tomorrow.
            <br />
            <br />
            Our foundation year programme is tailored to your learning journey, offering unwavering support throughout
            your foundation year and beyond.
          </Typography>

          <Button
            variant="outlined"
            color="inherit"
            size="large"
            href="/courses/"
            endIcon={<Iconify icon="carbon:chevron-right" />}
          >
            View Courses
          </Button>
        </Grid>
        {!isMdUp && renderVisionVideo(isMdUp)}
      </Grid>

      <Divider sx={{ mt: { xs: 7, md: 8 } }} style={{ borderColor: "transparent" }} />

      <NumbersSPC />
    </>
  );
};

export default WhoWeAreSPC;
