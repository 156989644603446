import { Link, Stack, Divider, Container, Typography, IconButton, Unstable_Grid2 as Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

import { StyledLink } from "./styles";
import { footer_routes } from "../../../config/routes";
import AweberModal from "../../../custom_components/AweberModal";
import useResponsive from "../../../hooks/useResponsive";
import Iconify from "../../icon/Iconify";
import { shouldHideHeader } from "../header/Header";

export default function Footer() {
  const isMdUp = useResponsive("up", "md");

  const location = useLocation();
  const hideHeader = shouldHideHeader(location);
  if (hideHeader) return <></>;

  const currentYear = new Date().getFullYear();

  const divider = (
    <>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: isMdUp ? "primary.main" : "primary.light",
          borderWidth: "1px",
          marginTop: isMdUp ? "0rem" : "1.5rem",
          marginLeft: "2rem",
          marginRight: "1rem",
        }}
      />
    </>
  );

  const footerLinks = (
    <Stack
      spacing={2.5}
      alignItems="flex-start"
      style={{
        marginTop: isMdUp ? "1rem" : "2.5rem",
      }}
    >
      {footer_routes.map((link) => (
        <StyledLink key={link.title} to={link.path}>
          {link.title}
        </StyledLink>
      ))}
    </Stack>
  );

  const socialLinks = (
    <Stack
      direction="column"
      alignItems="center"
      style={{
        marginTop: isMdUp ? "0rem" : "1.5rem",
      }}
    >
      {_socials.map((social) => (
        <a href={social.url} key={social.value} target="_blank" rel="noreferrer">
          <IconButton
            sx={{
              color: "text.secondary",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            <Iconify icon={social.icon} style={{ width: "1.75rem", height: "auto" }} />
          </IconButton>
        </a>
      ))}
    </Stack>
  );

  const mainFooter = (
    <>
      <Divider />

      <Container
        sx={{
          overflow: "hidden",
          py: { xs: 6, md: 6 },
        }}
      >
        <Grid container justifyContent={{ xs: "flex-start", md: "space-evenly" }}>
          <Grid xs={12} md={4}>
            <Stack spacing={{ xs: 3, md: 5 }}>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="h6">Sign Up for Pre-Reg Shortcuts Updates</Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Enter your email below to access free GPhC style questions, resources, updates and more.
                  </Typography>
                </Stack>

                <AweberModal />
              </Stack>
            </Stack>
          </Grid>
          {isMdUp && (
            <>
              {divider}
              {footerLinks}
              {divider}
              {socialLinks}
            </>
          )}
        </Grid>
        {!isMdUp && (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {divider}
            {footerLinks}
            {divider}
            <div style={{ marginRight: "1rem" }}>{socialLinks}</div>
          </div>
        )}
      </Container>
      <Divider />
      <Container>
        <Stack
          spacing={2.5}
          direction={{ xs: "column", md: "row" }}
          justifyContent="center"
          sx={{ py: 3, textAlign: "center", gap: { md: "3rem" } }}
        >
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            © {currentYear} Pre Reg Shortcuts Ltd. All rights reserved
          </Typography>

          <Stack direction="row" spacing={3} justifyContent="center">
            <Link variant="caption" sx={{ color: "text.secondary" }} href="/terms-and-conditions/">
              Terms & Conditions
            </Link>

            <Link variant="caption" sx={{ color: "text.secondary" }} href="/privacy-policy/">
              Privacy Policy
            </Link>
            <Link variant="caption" sx={{ color: "text.secondary" }} href="/initial-disclosure-document/">
              Initial Disclosure Document
            </Link>
            <Link variant="caption" sx={{ color: "text.secondary" }} href="/status-disclosure/">
              Status Disclosure
            </Link>
          </Stack>
        </Stack>
      </Container>
    </>
  );

  return <footer>{mainFooter}</footer>;
}

export const _socials = [
  {
    value: "facebook",
    label: "FaceBook",
    icon: "carbon:logo-facebook",
    color: "#1877F2",
    url: "https://www.facebook.com/preregistrationpharmacists/?locale=en_GB",
  },
  {
    value: "instagram",
    label: "Instagram",
    icon: "carbon:logo-instagram",
    color: "#E02D69",
    url: "https://www.instagram.com/preregshortcutsofficial/?hl=en",
  },
  {
    value: "youtube",
    label: "YouTube",
    icon: "carbon:logo-youtube",
    color: "#FF0000",
    url: "https://www.youtube.com/channel/UCj1Lx4xNGk7sHQ1O7H2XDEQ",
  },
  {
    value: "linktree",
    label: "Linktree",
    icon: "simple-icons:linktree",
    color: "#39E09B",
    url: "https://linktr.ee/preregshortcuts",
  },
  {
    value: "linkedin",
    label: "Linkedin",
    icon: "carbon:logo-linkedin",
    color: "#007EBB",
    url: "https://uk.linkedin.com/company/preregshortcuts",
  },
];
