import axios from "axios";
import { useEffect, useState } from "react";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { workshopsEndpoint } from "src/urls";
import DateService from "src/utils/dateService";

const mapWorkshopDtoToModel = (workshopDto) => {
  return {
    workshopId: workshopDto.id,
    title: workshopDto.title,
    description: workshopDto.description,
    start: DateService.parseServerDate(workshopDto.start),
    end: DateService.parseServerDate(workshopDto.end),
    zoomLink: workshopDto.zoom_link,
    slug: workshopDto.slug,
    tutorObj: workshopDto.tutor,
    resourceId: workshopDto.resource,
  };
};

const useWorkshops = () => {
  const isLightMode = useIsLightMode();

  const [workshops, setWorkshops] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(workshopsEndpoint)
      .then((response) => {
        const workshops = response.data;
        setWorkshops(workshops.map(mapWorkshopDtoToModel));
      })
      .catch(() => {
        errorToast("Error loading workshops, please try again later.", isLightMode);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { workshops, isLoading };
};

export default useWorkshops;
