import { Card } from "@mui/material";

export default function MyCard({ customStyle, children }) {
  return (
    <Card
      sx={{
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        p: 2.5,
        mt: 1,
        mb: 1,
      }}
      style={customStyle}
    >
      {children}
    </Card>
  );
}
