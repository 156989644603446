import { Grid } from "@mui/material";

import StepLabel from "./StepLabel";

export default function WorkshopsHowItWorks() {
  return (
    <Grid item xs={12} md={4}>
      <h2
        style={{
          marginBottom: "1.5rem",
        }}
      >
        How It Works
      </h2>
      <StepLabel
        step="1"
        title="Choose Workshop"
        body="Choose your workshop by selecting Buy Now to secure your spot for the workshop (limited spaces available)."
      />
      <StepLabel
        step="2"
        title="Workshop Confirmation"
        body="Receive confirmation via email of your enrolment and instructions on how to register for the workshop."
      />
      <StepLabel
        step="3"
        title="Day Of The Workshop"
        body="Simply click on the Zoom link sent to your email and join the live session."
      />
    </Grid>
  );
}
