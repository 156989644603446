import { Typography, Button } from "@mui/material";
import toast from "react-hot-toast";

import { DARK_MODE_TOAST_STYLE, LIGHT_MODE_TOAST_STYLE } from "src/App";
import { TEN_MINUTES } from "src/utils/duration";

export const confirmToast = ({
  message,
  isLightMode,
  onConfirm,
  onCancel = () => {},
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  toast(
    (t) => (
      <div style={{ minWidth: "250px" }}>
        <Typography variant="body1" style={{ marginBottom: "0.75rem" }}>
          {message}
        </Typography>
        <div style={{ display: "flex", gap: "10px", float: "right" }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              toast.dismiss(t.id);
              onCancel();
            }}
            style={{ borderColor: "lightgrey" }}
          >
            <Typography variant="body2" style={{ textTransform: "none", fontWeight: "bold" }}>
              {cancelText}
            </Typography>{" "}
          </Button>

          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              toast.dismiss(t.id);
              onConfirm();
            }}
            style={{ backgroundColor: "#212B36", color: "white" }}
            data-cy="ConfirmToast_ConfirmButton"
          >
            <Typography variant="body2" style={{ textTransform: "none", fontWeight: "bold" }}>
              {confirmText}
            </Typography>
          </Button>
        </div>
      </div>
    ),
    {
      duration: TEN_MINUTES,
      style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
    }
  );
};
