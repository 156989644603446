import axios from "axios";

import { dismissAllToasts, errorToast, successToast } from "src/components_with_stories/toast";
import DateService from "src/utils/dateService";

const SAVE_ATTEMPT_ENDPOINT = `/api/user_finish_question_set/`;
const GET_ATTEMPT_ENDPOINT = `/api/user_get_question_set_attempt/`;

class QuestionSetRepostiory {
  generateRequestData(questionSet, start, end, totalDuration, attemptData) {
    const setDuration = questionSet.duration;
    const extraTime = totalDuration - setDuration;

    return {
      question_set_id: questionSet.questionSetId,
      start: DateService.formatForAPI(start),
      end: DateService.formatForAPI(end),
      extra_time: extraTime,
      attempt_data: attemptData,
    };
  }

  async saveAttempt(requestData) {
    return await axios.post(SAVE_ATTEMPT_ENDPOINT, requestData);
  }

  async attemptToSaveMockPaperAttemptAgain(isLightMode) {
    const requestData = localStorage.getItem("mp_failedToSaveAttempt");
    if (requestData) {
      console.log("Found a mock paper attempt that failed to save. Retrying...");
      const data = JSON.parse(requestData);
      this.saveAttempt(data)
        .then(() => {
          dismissAllToasts();
          console.log("Attempt saved successfully");
          localStorage.removeItem("mp_failedToSaveAttempt");
          successToast("Mock Paper attempt saved successfully.", isLightMode);
        })
        .catch(() => {
          errorToast(
            "Error saving Mock Paper attempt. Please refresh the page. If the issue persists, contact support.",
            isLightMode
          );
        });
    } else {
      console.log("No failed mock paper attempts found. Skipping retry...");
    }
  }

  async getAttempt(questionSetId) {
    return await axios.post(GET_ATTEMPT_ENDPOINT, {
      question_set_id: questionSetId,
    });
  }
}

export default QuestionSetRepostiory;
