import { Typography } from "@mui/material";
import { usePrevious } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { DARK_MODE_TOAST_STYLE, LIGHT_MODE_TOAST_STYLE } from "src/App";
import useLocalStorage from "src/hooks/useLocalStorage";
import DateService from "src/utils/dateService";
import { TEN_SECONDS } from "src/utils/duration";
import { calculationsBank, clinicalMixedBank, getBankName } from "src/utils/format_bank";

import QBQuestion, { paleOrange } from "./qb_question/QBQuestion";
import QBQuizMenu from "./QBQuizMenu";
import QBReviewAndFeedbackMenu from "./QBReviewAndFeedbackMenu";
import QBTimer from "./QBTimer";
import {
  QUIZ_NOT_STARTED,
  QUIZ_IN_PROGRESS_ON_QUESTION_PAGE,
  QUIZ_IN_PROGRESS_ON_REVIEW_PAGE,
  QUIZ_FINISHED_FEEDBACK_PAGE,
  QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME,
  QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE,
  QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME,
  QUIZ_PREVIEW_MODE,
} from "./QuizStates";
import { confirmToast } from "./toast";
import { formatAttemptTime } from "./utils/formatAttemptTime";

export const weightingOptions = ["Low", "Medium", "High"];
const REVIEW_PAGE_SUFFIX = "/review";
const FEEDBACK_PAGE_SUFFIX = "/feedback";

const QBQuiz = ({ getQuestions, isLightMode, saveQuizAttempt, trial, bank, bankUrl }) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentQuestionIndex = getCurrentQuestionIndex(params);

  const [quizMetaData, setQuizMetaData] = useLocalStorage("qb_quizMetaData", getDefaultMetaData(trial, bank));

  const [quizState, setQuizState] = useLocalStorage("qb_quizState", QUIZ_NOT_STARTED);
  const [questions, setQuestions] = useLocalStorage("qb_questions", null);
  const [timerHidden, setHideTimer] = useLocalStorage("qb_timerHidden", false);

  const [quizExpiryString, setQuizExpiry] = useLocalStorage("qb_quizExpiry", null);
  const quizExpiry = quizExpiryString ? new Date(quizExpiryString) : null;
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const currentUrl = window.location.href;
  const previousUrl = usePrevious(currentUrl);

  useEffect(() => {
    // user left the quiz page, reset the quiz
    const urlContainsNumber = currentUrl.split("question-bank")[1].match(/\d+/g);
    const urlContainedReview = currentUrl.includes(REVIEW_PAGE_SUFFIX);
    const urlContainsFeedback = currentUrl.includes(FEEDBACK_PAGE_SUFFIX);
    const inQuiz = urlContainsNumber || urlContainedReview || urlContainsFeedback;

    if (currentQuestionIndex === null && !inQuiz) {
      console.log("Resetting Quiz!");
      setQuizState(QUIZ_NOT_STARTED);
      setQuestions(null);
      setLoadingQuestions(false);
      setQuizMetaData(getDefaultMetaData(trial, bank));
      setQuizExpiry(null);
      setHideTimer(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (previousUrl === currentUrl) return;
    if (previousUrl === null) return;
    if (currentUrl === null) return;

    const previousUrlContainsNumber = previousUrl.split("question-bank")[1].match(/\d+/g);
    const previousUrlContainsReview = previousUrl.includes(REVIEW_PAGE_SUFFIX);
    const previousUrlContainsFeedback = previousUrl.includes(FEEDBACK_PAGE_SUFFIX);

    const currentUrlContainsReview = currentUrl.includes(REVIEW_PAGE_SUFFIX);
    const currentUrlContainsFeedback = currentUrl.includes(FEEDBACK_PAGE_SUFFIX);
    const currentUrlIsQuestion = !currentUrlContainsReview && !currentUrlContainsFeedback;

    // went from Review page BACK to question page
    if (previousUrlContainsReview && currentUrlIsQuestion) {
      if (quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE) {
        setQuizState(QUIZ_IN_PROGRESS_ON_QUESTION_PAGE);
        return;
      }
    }

    // went from Feedback page BACK to question page
    if (previousUrlContainsFeedback && currentUrlIsQuestion) {
      if (quizState === QUIZ_FINISHED_FEEDBACK_PAGE) {
        setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE);
        return;
      } else if (quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME) {
        setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME);
        return;
      }
    }

    // went from question page to Review page
    if (previousUrlContainsNumber && currentUrlContainsReview) {
      if (quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE) {
        setQuizState(QUIZ_IN_PROGRESS_ON_REVIEW_PAGE);
        return;
      }
    }

    // went from question page to Feedback page
    if (previousUrlContainsNumber && currentUrlContainsFeedback) {
      if (quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE) {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE);
        return;
      } else if (quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME) {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME);
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  const setCurrentQuestionIndex = (idx) => {
    navigate(`/question-bank/quiz/${bankUrl}/${idx}`);
  };

  // when the quiz starts, retrieve data, set questions and
  // current question index
  const onQuizStart = async (selectedBank, selectedWeighting, numQuestions, duration) => {
    setLoadingQuestions(true);

    setQuizMetaData({
      selectedBank,
      selectedWeighting,
      numQuestions: parseInt(numQuestions),
      duration,
      startTime: DateService.newDate(),
    });
    setQuizExpiry(getExpiryTimestamp(duration));

    // load data from API, show loading spinner on Start button
    // once data has loaded, set it in state and then set quiz started

    const questionsFromApi = await getQuestions({
      bank: selectedBank,
      weighting: selectedWeighting,
      numQuestions: parseInt(numQuestions),
    });

    if (questionsFromApi === null) {
      setLoadingQuestions(false);
      return;
    }

    const questions = questionsFromApi.map((q, idx) => {
      const questionNumber = idx + 1;
      const totalNumberOfQuestions = questionsFromApi.length;

      return mapQuestionDtoToQuestionProps(q, questionNumber, totalNumberOfQuestions);
    });

    setLoadingQuestions(false);
    setQuestions(questions);
    setQuizState(QUIZ_IN_PROGRESS_ON_QUESTION_PAGE);
    setCurrentQuestionIndex(0);
    window.scrollTo(0, 0);
  };

  // When the quiz timer has expired, forcefully end!
  const onQuizExpire = () => {
    toast(
      "You ran out of time! Your quiz has been submitted automatically. Check the feedback below for more information.",
      {
        icon: "⏳",
        duration: TEN_SECONDS,
        style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
      }
    );
    enterFeedbackMode({ outOfTime: true });
  };

  const goToQuestion = (idx) => {
    window.scrollTo(0, 0);
    setCurrentQuestionIndex(idx);
  };

  const setAnswerId = (questionIndex, newAnswerId) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].answerId = newAnswerId;
      return newQuestions;
    });
  };

  const toggleQuestionFlag = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].isFlagged = !newQuestions[questionIndex].isFlagged;
      return newQuestions;
    });
  };

  const nextQuestion = () => {
    const wasOnLastQuestion = currentQuestionIndex === questions.length - 1;
    if (wasOnLastQuestion) {
      enterFeedbackMode({ outOfTime: false });
      return;
    }
    goToQuestion(currentQuestionIndex + 1);
  };

  const backQuestion = () => {
    const wasOnFirstQuestion = currentQuestionIndex === 0;
    if (wasOnFirstQuestion) return;
    goToQuestion(currentQuestionIndex - 1);
  };

  const enterReviewMode = () => {
    window.scrollTo(0, 0);
    setQuizState(QUIZ_IN_PROGRESS_ON_REVIEW_PAGE);

    navigate(`/question-bank/quiz/${bankUrl}${REVIEW_PAGE_SUFFIX}`);
  };

  const enterFeedbackMode = ({ outOfTime }) => {
    const actuallyEnterFbMode = () => {
      if (outOfTime) {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME);
      } else {
        setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE);
      }
      window.scrollTo(0, 0);

      const newQuizMetaData = {
        ...quizMetaData,
        numberOfQuestionsAnsweredCorrectly: questions.filter((q) => q.answerId === q.question.actualAnswerId).length,
        timeTakenInSeconds: DateService.newDate().diff(quizMetaData.startTime, "second"),
      };

      setQuizMetaData(newQuizMetaData);
      saveQuizAttempt(newQuizMetaData);

      navigate(`/question-bank/quiz/${bankUrl}${FEEDBACK_PAGE_SUFFIX}`);
    };

    const flaggedQuestions = questions.filter((q) => q.isFlagged);
    const numFlaggedQuestions = flaggedQuestions.length;
    const hasFlaggedQuestions = numFlaggedQuestions > 0;
    if (outOfTime === false && hasFlaggedQuestions) {
      const plural = numFlaggedQuestions > 1 ? "s" : "";
      confirmToast({
        message: `You have ${numFlaggedQuestions} flagged question${plural}. Are you sure you want to submit?`,
        isLightMode: isLightMode,
        onConfirm: actuallyEnterFbMode,
        confirmText: "Submit",
        cancelText: "Review Answers",
      });
    } else {
      actuallyEnterFbMode();
    }
  };

  const showTimer = quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE || quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE;

  const showQuizSetupMenu = quizState === QUIZ_NOT_STARTED;

  const showQuestion = quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE;

  const showReviewMenu = quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE;

  const showFeedbackMenu =
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE || quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME;

  const showQuestionWithFeedback =
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  const shouldBeAQuestion =
    quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME ||
    quizState === QUIZ_PREVIEW_MODE;
  if (currentQuestionIndex === null && shouldBeAQuestion) {
    return (
      <div>
        <Typography>
          Please go back to the previous page and use the buttons within the quiz to navigate between pages.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div style={{ position: "relative" }}>
        {showTimer && (
          <div style={{ float: "right" }} data-cy="QBQuiz_Timer">
            <QBTimer
              expiryTimestamp={quizExpiry}
              totalDurationInMinutes={quizMetaData.duration}
              onExpire={onQuizExpire}
              isLightMode={isLightMode}
              timerHidden={timerHidden}
              toggleHideTimer={() => setHideTimer(!timerHidden)}
            />
          </div>
        )}
        <Title
          quizState={quizState}
          selectedBank={quizMetaData.selectedBank}
          selectedWeighting={quizMetaData.selectedWeighting}
          trial={trial}
        />
      </div>

      {showQuizSetupMenu && (
        <div>
          <QBQuizMenu
            onStart={onQuizStart}
            loadingQuestions={loadingQuestions}
            isLightMode={isLightMode}
            disableConfig={trial}
            selectedBank={quizMetaData.selectedBank}
            initialWeighting={quizMetaData.selectedWeighting}
            initialNumQuestions={quizMetaData.numQuestions}
            initialDuration={quizMetaData.duration}
          />
        </div>
      )}

      {showQuestion && (
        <div>
          <QBQuestion
            obj={{ ...questions[currentQuestionIndex] }}
            quizState={quizState}
            nextQuestion={nextQuestion}
            backQuestion={backQuestion}
            setAnswerId={(newAnswerId) => {
              setAnswerId(currentQuestionIndex, newAnswerId);
            }}
            toggleFlag={() => {
              toggleQuestionFlag(currentQuestionIndex);
            }}
            enterReviewMode={enterReviewMode}
            returnToFeedback={() => {}}
          />
        </div>
      )}

      {showReviewMenu && (
        <QBReviewAndFeedbackMenu
          questions={questions}
          goToQuestion={(idx) => {
            goToQuestion(idx);
            setQuizState(QUIZ_IN_PROGRESS_ON_QUESTION_PAGE);
          }}
          toggleQuestionFlag={toggleQuestionFlag}
          submitQuiz={() => {
            enterFeedbackMode({ outOfTime: false });
          }}
          isFeedbackMenu={false}
        />
      )}

      {showFeedbackMenu && (
        <div>
          <Feedback quizMetaData={quizMetaData} ranOutOfTime={quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME} />

          <QBReviewAndFeedbackMenu
            questions={questions}
            goToQuestion={(idx) => {
              goToQuestion(idx);
              if (quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME) {
                setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME);
              } else {
                setQuizState(QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE);
              }
            }}
            toggleQuestionFlag={() => {}}
            submitQuiz={() => {}}
            isFeedbackMenu={true}
          />
        </div>
      )}

      {showQuestionWithFeedback && (
        <div style={{ marginTop: "28px" }}>
          <QBQuestion
            obj={{ ...questions[currentQuestionIndex] }}
            quizState={quizState}
            nextQuestion={() => {}}
            backQuestion={() => {}}
            setAnswerId={() => {}}
            toggleFlag={() => {}}
            enterReviewMode={() => {}}
            returnToFeedback={() => {
              if (quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME) {
                setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME);
              } else {
                setQuizState(QUIZ_FINISHED_FEEDBACK_PAGE);
              }
              navigate(`/question-bank/quiz/${bankUrl}/feedback`);
            }}
          />
        </div>
      )}

      <br />
    </div>
  );
};

export default QBQuiz;

export const mapQuestionDtoToQuestionProps = (questionDto, questionNumber, totalNumberOfQuestions) => {
  const isCalc = questionDto.is_calculation;
  return {
    isCalculation: isCalc,
    currentQuestionNumber: questionNumber,
    totalNumberOfQuestions: totalNumberOfQuestions,
    question: {
      questionId: questionDto.id,
      questionTitle: questionDto.title,
      questionText: questionDto.text,
      possibleAnswers: questionDto.answers.map(({ text, answer_number }) => {
        return { answerId: answer_number, answerText: text };
      }),
      actualAnswerId: isCalc ? questionDto.correct_answer : questionDto.correct_answer_number,
      actualAnswerReason: questionDto.why,
      answerUnit: questionDto.answer_unit,
      resourceImageUrl: questionDto.resource_image,
      visible: questionDto.visible,
      questionSet: questionDto.question_set,
    },
    answerId: undefined,
    isFlagged: false,
  };
};

const Title = ({ quizState, selectedBank, selectedWeighting, trial }) => {
  const bank = getBankName(selectedBank);
  const showSelectedWeighting = selectedBank !== calculationsBank && selectedWeighting !== null;

  const beforeQuizStarts = quizState === QUIZ_NOT_STARTED;
  const quizInProgress = quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE;
  const inReviewMode = quizState === QUIZ_IN_PROGRESS_ON_REVIEW_PAGE;
  const inFeedbackMode =
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  return (
    <div>
      <Typography variant="h3" data-cy="QBQuiz_Title">
        {trial ? "Question Bank Trial " : ""}
        {beforeQuizStarts && `Quiz: Setup`}
        {quizInProgress && `Quiz: In Progress`}
        {inReviewMode && `Quiz: Review`}
        {inFeedbackMode && `Quiz: Feedback`}
      </Typography>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Bank: {bank} {showSelectedWeighting ? `(${selectedWeighting.toLowerCase()} weighting)` : ""}
      </Typography>
    </div>
  );
};

const Feedback = ({ quizMetaData, ranOutOfTime }) => {
  const { numQuestions: totalNumberOfQuestions, numberOfQuestionsAnsweredCorrectly, timeTakenInSeconds } = quizMetaData;

  const percentage = ((numberOfQuestionsAnsweredCorrectly / totalNumberOfQuestions) * 100).toFixed(0);

  const timeTaken = formatAttemptTime(timeTakenInSeconds);

  return (
    <div
      style={{
        marginTop: "0.8rem",
        backgroundColor: paleOrange,
        padding: "1rem",
      }}
    >
      {ranOutOfTime && (
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          You ran out of time. Quiz was submitted automatically.
        </Typography>
      )}

      <Typography sx={{ fontWeight: "bold" }}>
        You answered {numberOfQuestionsAnsweredCorrectly}/{totalNumberOfQuestions} questions correctly. You scored:{" "}
        {percentage}%.
      </Typography>
      <Typography>Time taken: {timeTaken}</Typography>
    </div>
  );
};

function getDefaultMetaData(trial, bank) {
  if (trial && bank === calculationsBank) {
    return tryCalculationsQuizMetaData;
  }
  if (trial && bank === clinicalMixedBank) {
    return tryClinicalMixedQuizMetaData;
  }
  return { ...defaultQuizMetaData, selectedBank: bank };
}

const defaultQuizMetaData = {
  selectedBank: null,
  selectedWeighting: null,
  numQuestions: null,
  duration: null,
  startTime: null,
  numberOfQuestionsAnsweredCorrectly: null,
  timeTakenInSeconds: null,
};

const tryCalculationsQuizMetaData = {
  ...defaultQuizMetaData,
  selectedBank: calculationsBank,
  numQuestions: 5,
  duration: 15,
};

const tryClinicalMixedQuizMetaData = {
  ...defaultQuizMetaData,
  selectedBank: clinicalMixedBank,
  selectedWeighting: weightingOptions[1],
  numQuestions: 5,
  duration: 5,
};

export const getCurrentQuestionIndex = (params) => {
  const { questionIndex } = params;
  const currentQuestionIndex = questionIndex?.length > 0 ? parseInt(questionIndex) : null;
  if (isNaN(currentQuestionIndex)) return null;
  return currentQuestionIndex;
};

export const getExpiryTimestamp = (numMinutes) => {
  const numSeconds = numMinutes * 60;
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + numSeconds);
  return expiryTimestamp;
};
