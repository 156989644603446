import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useContext } from "react";

import imagePlaceholder from "src/assets/placeholder.jpeg";
import { confirmToast, errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import BasketRepostiory from "src/repository/basketRepository";

import { AccessContext } from "./AccessContext";
import { AppContext } from "./AppContext";

export const BASKET_ITEMS_KEY = "basketItems";
export const BASKET_ID_KEY = "basketId";

export const BasketContext = React.createContext();

export const BasketContextProvider = (props) => {
  const { userId } = useContext(AppContext);
  const { refreshResources } = useContext(AccessContext);

  const [items, setItems] = useLocalStorage(BASKET_ITEMS_KEY, []);
  const [basketId, setBasketId] = useLocalStorage(BASKET_ID_KEY, null);

  const isLightMode = useIsLightMode();
  const basketRepository = new BasketRepostiory(basketId);

  const addItemToBasket = async ({ name, description, image, resourceModel }) => {
    try {
      const newItem = createBasketItem(name, description, image, resourceModel);
      const isAlreadyInBasket = isItemInBasket(resourceModel);

      if (isAlreadyInBasket) {
        errorToast("Item already in basket.");
      }

      const [id, basketItemId] = await basketRepository.handleAddToBasket(userId, resourceModel.id);

      setBasketId(id);
      setItems((prevItems) => {
        return [...prevItems, { ...newItem, basketItemId: basketItemId }];
      });

      return true;
    } catch (e) {
      console.error(e);
      errorToast("Error adding item to basket.");
    }

    return false;
  };

  const removeItemFromBasket = async (stripePriceId, resourceId) => {
    const item = items.find((item) => item.stripePriceId === stripePriceId);

    try {
      await basketRepository.removeItemFromBasket(resourceId, item.basketItemId);
    } catch (e) {
      console.error(e);
      errorToast("Error removing item from basket.");
      return false;
    }

    setItems((prevItems) => {
      return prevItems.filter((item) => item.stripePriceId !== stripePriceId);
    });

    return true;
  };

  const forceRemoveItemsFromBasket = async (itemsToRemove) => {
    const itemsExistInBasket = itemsToRemove.every((itemToRemove) => {
      return items.some((item) => item.stripePriceId === itemToRemove.stripePriceId);
    });
    if (!itemsExistInBasket) {
      errorToast("Error removing items from basket. Items not found.");
      return;
    }
    try {
      await basketRepository.removeItemsFromBasket(itemsToRemove);

      setItems((prevItems) => {
        return prevItems.filter(
          (item) => !itemsToRemove.some((itemToRemove) => item.stripePriceId === itemToRemove.stripePriceId)
        );
      });
    } catch (e) {
      console.error(e);
      errorToast("Error removing item from basket.");
    }
  };

  const removeItemFromBasketOnConfirm = (stripePriceId, resourceId, postAction) => {
    const item = items.find((item) => item.stripePriceId === stripePriceId);

    if (!item) {
      errorToast("Error removing item from basket. Item not found.");
      return;
    }

    confirmToast({
      message: (
        <p style={{ marginTop: 0 }}>
          Are you sure you want to remove <strong>{item.name}</strong> from your basket?
        </p>
      ),
      isLightMode: isLightMode,
      onConfirm: () => {
        removeItemFromBasket(stripePriceId, resourceId).then(() => {
          postAction();
        });
      },
      confirmText: "Remove",
      onCancel: postAction,
    });
  };

  const isItemInBasket = (resourceModel) => {
    return items.some((item) => item.stripePriceId === resourceModel.stripePriceId);
  };

  const checkoutSuccess = () => {
    setBasketId(null);
    setItems([]);
    refreshResources();
  };

  return (
    <BasketContext.Provider
      value={{
        items,
        basketId,
        isItemInBasket,
        addItemToBasket,
        removeItemFromBasket: removeItemFromBasketOnConfirm,
        forceRemoveItemsFromBasket,
        checkoutSuccess,
      }}
    >
      {props.children}
    </BasketContext.Provider>
  );
};

const createBasketItem = (name, description, image, resourceModel) => {
  if (!name || !description || !resourceModel) {
    throw new Error("Error adding item to basket. Missing name, description or resourceModel.");
  }

  if (name.length === 0 || description.length === 0) {
    throw new Error("Error adding item to basket. Name or description is empty.");
  }

  if (!resourceModel.stripePriceId || !resourceModel.cost || !resourceModel.originalCost || !resourceModel.id) {
    throw new Error("Error adding item to basket. Missing stripePriceId, id, cost or originalCost.");
  }

  if (resourceModel.stripePriceId.length === 0) {
    throw new Error("Error adding item to basket. stripePriceId is empty.");
  }

  if (resourceModel.cost.length === 0 || resourceModel.originalCost.length === 0) {
    throw new Error("Error adding item to basket. cost or originalCost is empty.");
  }

  try {
    const cost = parseFloat(resourceModel.cost);
    const originalCost = parseFloat(resourceModel.originalCost);

    if (isNaN(cost) || isNaN(originalCost)) {
      throw new Error("Error adding item to basket. cost or originalCost is not a number (NaN).");
    }

    const newItem = {
      name: name,
      description: description,
      image: image ?? imagePlaceholder,
      stripePriceId: resourceModel.stripePriceId,
      resourceId: resourceModel.id,
      cost: cost,
      originalCost: originalCost,
      type: resourceModel.type,
      includedInPremium: resourceModel.isIncludedInPremium,
    };

    return newItem;
  } catch (e) {
    throw new Error("Error adding item to basket. cost or originalCost is not a number.");
  }
};
