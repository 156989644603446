import { Tooltip, Link, ListItemText } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import Label from "src/components/labels/Label";

import { StyledItem, StyledIcon, StyledDotIcon } from "./styles";

export default function NavItemMobile({ item, active, isExternalLink, ...other }) {
  const { title, path, icon, caption } = item;

  const isComingSoon = item.isComingSoon === true;
  const isNew = item.isNew === true;

  const renderContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <StyledItem depth={1} active={active} caption={!!caption} {...other}>
        {icon && <StyledIcon>{icon}</StyledIcon>}

        <StyledIcon>
          <StyledDotIcon active={active} />
        </StyledIcon>

        <ListItemText
          primary={title}
          secondary={
            caption && (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            // noWrap: true,
            component: "span",
            variant: active ? "subtitle2" : "body2",
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: "caption",
          }}
        />
      </StyledItem>
      {isComingSoon && (
        <Label
          color="info"
          style={{
            marginTop: "4px",
            marginLeft: "3.35rem",
            width: "100px",
            marginBottom: "0.5rem",
          }}
        >
          Coming Soon
        </Label>
      )}
      {isNew && (
        <Label
          color="success"
          style={{
            marginTop: "-4px",
            marginLeft: "3.35rem",
            width: "100px",
            marginBottom: "0.5rem",
          }}
        >
          New
        </Label>
      )}
    </div>
  );

  // ExternalLink
  if (isExternalLink)
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );

  // Default
  return (
    <Link component={RouterLink} to={path} underline="none">
      {renderContent}
    </Link>
  );
}
