import { calculationsBank, clinicalMepBank, clinicalOtcBank, clinicalTherapeuticsBank } from "src/utils/format_bank";

import { getQuestionsPerBank } from "../utils";
import MetaDataAccordion from "./MetaDataAccordion";

const QuestionsByBank = ({ questionsMetaData }) => {
  const calculationQuestions = getQuestionsPerBank(questionsMetaData, calculationsBank);
  const clinicalOTCQuestions = getQuestionsPerBank(questionsMetaData, clinicalOtcBank);
  const clinicalMEPQuestions = getQuestionsPerBank(questionsMetaData, clinicalMepBank);
  const clinicalTherapeuticsQuestions = getQuestionsPerBank(questionsMetaData, clinicalTherapeuticsBank);

  const numCalculationQuestions = calculationQuestions.length;
  const numClinicalOTCQuestions = clinicalOTCQuestions.length;
  const numClinicalMEPQuestions = clinicalMEPQuestions.length;
  const numClinicalTherapeuticsQuestions = clinicalTherapeuticsQuestions.length;

  const numClinicalMixedQuestions =
    numClinicalMEPQuestions + numClinicalOTCQuestions + numClinicalTherapeuticsQuestions;

  return (
    <MetaDataAccordion
      title="Visible Questions By Bank"
      content={
        <>
          <p style={{ margin: "12px 0" }}>Calculations: {numCalculationQuestions}</p>
          <p style={{ margin: "12px 0" }}>
            ClinicalMixed (sum of all three clinical banks): {numClinicalMixedQuestions}
          </p>
          <p style={{ margin: "12px 0" }}>ClinicalOTC: {numClinicalOTCQuestions}</p>
          <p style={{ margin: "12px 0" }}>ClinicalMEP: {numClinicalMEPQuestions}</p>
          <p style={{ margin: "12px 0" }}>ClinicalTherapeutics: {numClinicalTherapeuticsQuestions}</p>
        </>
      }
    />
  );
};

export default QuestionsByBank;
