import axios from "axios";

import { BASKET_ID_KEY, BASKET_ITEMS_KEY } from "src/context/BasketContext";
import DateService from "src/utils/dateService";

const BASKET_ENDPOINT = `/api/baskets/`;
const BASKET_ITEM_ENDPOINT = `/api/basket_items/`;

class BasketRepostiory {
  _basketId = null;

  constructor(basketId) {
    this._basketId = basketId;
  }

  // returns the basket
  async _createEmptyBasket(userId) {
    const requestData = { user: userId };
    const response = await axios.post(BASKET_ENDPOINT, requestData);
    if (response.status !== 201) throw new Error("Error creating basket.");
    const basket = response.data;
    this._basketId = basket.id;
    return basket;
  }

  // returns nothing
  async _addItemToBasket(resourceId) {
    const requestData = {
      basket: this._basketId,
      resource: resourceId,
    };
    try {
      const response = await axios.post(BASKET_ITEM_ENDPOINT, requestData);
      if (response.status !== 201) throw new Error();
      const basketItem = response.data;
      return basketItem;
    } catch (e) {
      // basket id not found, clear the basket
      if (e.response?.status === 400) this.resetBasket();
      throw new Error("Error adding item to basket.");
    }
  }

  async removeItemFromBasket(resourceId, basketItemId) {
    const requestData = {
      basket: this._basketId,
      resource: resourceId,
      date_removed: DateService.formatForAPI(DateService.newDate()),
    };
    const url = `${BASKET_ITEM_ENDPOINT}${basketItemId}/`;
    try {
      const response = await axios.put(url, requestData);
      if (response.status !== 200) throw new Error();
    } catch (e) {
      // basket item doesn't exist, clear the basket
      if (e.response?.status === 404) this.resetBasket();
      throw new Error("Error removing item from basket.");
    }
  }

  async removeItemsFromBasket(itemsToRemove) {
    const requestData = {
      basket_item_ids: itemsToRemove.map((item) => item.basketItemId),
    };
    const url = `/api/bulk_remove_basket_items/`;
    try {
      const response = await axios.put(url, requestData);
      if (response.status !== 200) throw new Error();
    } catch (e) {
      throw new Error("Error removing item from basket.");
    }
  }

  async handleAddToBasket(userId, resourceId) {
    try {
      if (this._basketId === null) {
        await this._createEmptyBasket(userId);
      }
      const basketItem = await this._addItemToBasket(resourceId);
      return [this._basketId, basketItem.id];
    } catch (e) {
      console.error(e);
      throw new Error("Error adding item to basket.");
    }
  }

  resetBasket() {
    this._basketId = null;
    localStorage.removeItem(BASKET_ITEMS_KEY);
    localStorage.removeItem(BASKET_ID_KEY);
    window.location.reload();
  }
}

export default BasketRepostiory;
