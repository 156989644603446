import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import FormProvider from "src/components/hook-form";
import { setNewPasswordSchema } from "src/components/main/header/ChangePasswordModal";
import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import PasswordField from "src/newComponents/form/PasswordField";

interface AuthSetNewPasswordFormProps {
  confirmPasswordReset: (password: string) => void;
}

export default function AuthSetNewPasswordForm({ confirmPasswordReset }: AuthSetNewPasswordFormProps) {
  const isLightMode = useIsLightMode();

  const defaultValues = {
    password1: "",
    password2: "",
  };

  const methods = useForm({
    resolver: yupResolver(setNewPasswordSchema()),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async ({ password1, password2 }: { password1: string; password2: string }) => {
    if (password1 === password2) {
      confirmPasswordReset(password1);
    } else {
      errorToast("Passwords don't match!", isLightMode);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5} alignItems="flex-end">
        <PasswordField name="password1" label="Password" hasFocus={true} tabIndex1={1} tabIndex2={4} />

        <PasswordField name="password2" label="Confirm Password" hasFocus={false} tabIndex1={2} tabIndex2={5} />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          tabIndex={3}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
