import { Grid } from "@mui/material";

import icon1 from "src/assets/workshopsBenefits/1.svg";
import icon2 from "src/assets/workshopsBenefits/2.svg";
import icon3 from "src/assets/workshopsBenefits/3.svg";

export default function WorkshopsBenefits({ isMobile }) {
  return (
    <>
      <h2
        style={{
          marginBottom: "1rem",
        }}
      >
        Benefits
      </h2>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={{
            xs: 1.5,
            md: 3,
          }}
        >
          <Grid item xs={12} md={4}>
            <img
              src={icon1}
              alt={"Career Options"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Career Options"}
            />
            <h3>Explore Career Options</h3>
            <p>Discover the perfect Pharmacy career for you!</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={icon2}
              alt={"Application Process"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Application Process"}
            />
            <h3>Application Process</h3>
            <p>Become confident in how to apply!</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={icon3}
              alt={"Interview Skills & CV Writing"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Interview Skills & CV Writing"}
            />
            <h3>Interview Skills & CV Writing</h3>
            <p>Stand out from your competition!</p>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
