import { Typography, Stack, Link } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";

import EmailIcon from "src/assets/large_email_icon.svg";
import Iconify from "src/components/icon/Iconify";
import { errorToast, successToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { resendAuthEndpoint } from "src/urls";
import { bgGradient } from "src/utils/cssStyles";
import { TEN_MINUTES } from "src/utils/duration";

import TwoFactorAuthForm from "./components/TwoFactorAuthForm";

function isStateValid(state: any) {
  if (!state) return false;
  if (typeof state !== "object") return false;
  if (typeof state.email !== "string") return false;
  return true;
}

const TwoFactorAuthPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const isLightMode = useIsLightMode();

  const email = isStateValid(state) ? state.email : null;
  if (!email) {
    navigate("/login/");
    return;
  }

  const resendOtp = () => {
    axios
      .post(resendAuthEndpoint)
      .then(() => {
        successToast("Code resent successfully. Check your email, including junk / spam folder.", isLightMode);
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          const message = error.response.data.error;
          errorToast(message, isLightMode);
          return;
        }
        const message = "Something went wrong. Please contact us for help.";
        errorToast(message, isLightMode, TEN_MINUTES);
        console.log(error);
      });
  };

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/verify/" }]}>
        <title>Verify Your Account | Pre-Reg Shortcuts</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <StyledRoot>
        <Stack
          spacing={4}
          sx={{
            p: 4,
            width: 1,
            mx: "auto",
            flexShrink: 0,
            maxWidth: 425,
            borderRadius: 2,
            bgcolor: "background.default",
            textAlign: { xs: "center", md: "left" },
            boxShadow: (theme: any) => theme.customShadows.z24,
          }}
        >
          <img
            alt="Verify your account icon"
            title="Verify your account icon"
            src={EmailIcon}
            width={64}
            height={64}
            style={{ margin: "auto" }}
            loading="eager"
          />

          <Typography variant="h3" style={{ marginTop: "24px" }}>
            Verify Your Account
          </Typography>

          <Typography variant="body2" color="text.secondary" style={{ marginTop: "8px", marginBottom: "12px" }}>
            Enter the 6 digit code sent to your email address. Be sure to check your junk / spam email. The code will
            expire in 10 minutes.
          </Typography>

          <TwoFactorAuthForm />

          <Typography color="text.secondary" variant="body2" style={{ marginTop: "16px" }}>
            Having trouble?{" "}
            <Link
              component={RouterLink}
              to={"https://drive.google.com/file/d/1ScHxbGhPLUDvq5MqbGgBZkmFFvtrDOr6/view?usp=sharing"}
              underline="always"
              target="_blank"
              color="text.secondary"
              tabIndex={3}
            >
              Support Guide
            </Link>
          </Typography>

          <Typography color="text.secondary" variant="body2" style={{ marginTop: "8px" }}>
            {"Don't have a code? "}
            <Link
              color="text.secondary"
              underline="always"
              style={{ cursor: "pointer" }}
              tabIndex={4}
              onClick={resendOtp}
            >
              Resend code
            </Link>
          </Typography>

          <Typography variant="body2" color="text.secondary" style={{ marginTop: "8px", fontStyle: "italic" }}>
            Warning: Password sharing violates our{" "}
            <Link
              component={RouterLink}
              to={"/terms-and-conditions/"}
              underline="always"
              target="_blank"
              color="text.secondary"
              tabIndex={3}
            >
              T&C's
            </Link>{" "}
            and will trigger detection systems. This will result in a non-refundable ban.
          </Typography>

          <Link
            href="/"
            color="inherit"
            variant="subtitle2"
            style={{ marginTop: "12px" }}
            sx={{
              mx: "auto",
              alignItems: "center",
              display: "inline-flex",
            }}
            tabIndex={5}
          >
            <Iconify icon="carbon:chevron-left" width={16} sx={{ mr: 1 }} />
            Return to home
          </Link>
        </Stack>
      </StyledRoot>
    </>
  );
};

export default TwoFactorAuthPage;

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: "/assets/background/overlay_1.jpg",
  }),
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
