import { Typography } from "@mui/material";

import { calculationsBank } from "src/utils/format_bank";

export const QBQuizInstructions = ({ selectedBank, isMockPaper }) => {
  const isCalculations = selectedBank === calculationsBank;

  const commonInstructions = [
    "Answer questions how you would as a Pharmacist, not a Trainee Pharmacist.",
    "Each correct answer carries one mark, incorrect or missed questions have no negative marking.",
    `${isMockPaper ? "Mock Paper" : "Quiz"} will automatically end when the timer runs out.`,
    "You will be given the option to review your answers before submitting if you have any time remaining.",
  ];

  const calculationsInstructions = [
    "Type your answer in the box provided below the question, be wary of decimal points.",
    "Calculators are allowed (use a GPhC approved Calculator).",
  ].concat(commonInstructions);

  const clinicalInstructions = [
    "Each question will include either 5 (SBA) or 8 (EMQ) options, select one choice per question.",
    "Calculators are not allowed.",
  ].concat(commonInstructions);

  const instructions = isCalculations ? calculationsInstructions : clinicalInstructions;

  return (
    <div data-cy="QBQuiz_Instructions">
      <Typography>Instructions:</Typography>
      <ol style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
        {instructions.map((instruction, idx) => {
          return <li key={idx}>{instruction}</li>;
        })}
      </ol>
    </div>
  );
};
