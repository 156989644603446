import { Divider } from "@mui/material";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { AccessContext } from "src/context/AccessContext";
import { FAQContext } from "src/context/FAQContext";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import QuestionBankTopSPC from "src/pages/landing/components/QuestionBankTopSPRC";
import PageTemplate from "src/pages/PageTemplate";

const QuestionBankPage = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("Question Bank");

  const { getResourceModelByResourceType } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceType("questionBank");
  const canAccessFullQB = resourceModel?.access?.userHasValidAccess ?? false;

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/question-bank/" }]}>
        <title>Question Bank | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Attempt GPhC-style questions in key areas like calculations, clinical topics, MEP, OTC, and more. Boost your exam preparation with our tailored resources in our question bank."
        />
        <meta
          name="keywords"
          content="GPhC registration assessment, Single best answer, extended matching questions, Calculations, Clinical, OTC, BNF"
        />
      </Helmet>
      {resourceModel === null && <LoadingScreen />}
      <PageTemplate>
        <QuestionBankTopSPC canAccessFullQB={canAccessFullQB} resourceModel={resourceModel} />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default QuestionBankPage;
