import { Typography, Card, Box, Stack, IconButton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import Iconify from "src/components/icon/Iconify";
import Image from "src/components/image/Image";

import { bgGradient } from "../../utils/cssStyles";

const StyledOverlay = styled("div")(({ theme }) => ({
  ...bgGradient({
    startColor: `${alpha(theme.palette.common.black, 0)} 0%`,
    endColor: `${theme.palette.common.black} 100%`,
  }),
  top: 0,
  left: 0,
  zIndex: 8,
  opacity: 0,
  width: "100%",
  height: "100%",
  position: "absolute",
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  "&:hover": { opacity: 1 },
}));

export default function TeamMember({ member }) {
  const { name, role, photo, linkedIn } = member;

  return (
    <Card
      sx={{
        height: "fit-content",
        margin: {
          xs: "1rem 3rem",
          sm: "1.5rem 2rem",
          md: "2rem 0.75rem",
        },
      }}
    >
      <Stack spacing={0.5} sx={{ textAlign: "center", pt: 3, pb: 1.5 }}>
        <Typography variant="h6">{name}</Typography>

        <Typography variant="body2" sx={{ color: "text.disabled" }}>
          {role}
        </Typography>
      </Stack>

      <Box sx={{ position: "relative" }}>
        <StyledOverlay>
          <Stack direction="row" justifyContent="center" sx={{ width: 1, zIndex: 9, bottom: 24, position: "absolute" }}>
            <IconButton color="primary" target="_blank" href={linkedIn}>
              <Iconify icon={"carbon:logo-linkedin"} />
            </IconButton>
          </Stack>
        </StyledOverlay>

        <Image src={photo} alt={name} />
      </Box>
    </Card>
  );
}
