import { Stack } from "@mui/material";

import NavListDesktop from "./NavListDesktop";

export default function NavDesktop({ leftRoutes, rightRoutes, sx }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Stack
        component="nav"
        direction="row"
        spacing={{ xs: 4, lg: 5.5 }}
        sx={{
          ml: 6,
          height: 1,
          ...sx,
        }}
      >
        {leftRoutes.map((link) => (
          <NavListDesktop key={link.title} item={link} />
        ))}
      </Stack>

      <Stack
        component="nav"
        direction="row"
        spacing={{ xs: 4, lg: 5.5 }}
        sx={{
          mr: 3,
          height: 1,
          ...sx,
        }}
      >
        {rightRoutes.map((link) => (
          <NavListDesktop key={link.title} item={link} />
        ))}
      </Stack>
    </div>
  );
}
