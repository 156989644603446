import AboutUsPage from "src/pages/_static/about_us/AboutUsPage";
import ContactUsPage from "src/pages/_static/contact_us/ContactUsPage";
import FAQPage from "src/pages/_static/frequently_asked_questions/FAQPage";
import HowItWorksPage from "src/pages/_static/how_it_works/HowItWorksPage";
import PrivacyPolicyPage from "src/pages/_static/privacy_policy/PrivacyPolicyPage";
import StatusDisclosurePage from "src/pages/_static/status_disclosure/StatusDisclosurePage";
import TermsAndConditionsPage from "src/pages/_static/terms_and_conditions/TermsAndConditionsPage";
import WhyUsPage from "src/pages/_static/why_us/WhyUsPage";
import AdditionalTrainingOptionsPage from "src/pages/additional_training_options/AdditionalTrainingOptionsPage";
import CheckoutPage from "src/pages/checkout/CheckoutPage";
import DesignatedSupervisorProgramme from "src/pages/designated_supervisor_programme/DesignatedSupervisorProgramme";
import LiveOnlineStudySessionsPage from "src/pages/live_online_study_sessions/LiveOnlineStudySessionsPage";
import MaintenanceModePage from "src/pages/maintenance_mode/MaintenanceModePage";
import AttemptMockPaperPage from "src/pages/mock_paper/AttemptMockPaperPage";
import MockPaperPage from "src/pages/mock_paper/MockPaperPage";
import FoundationTrainingProgrammePage from "src/pages/products/FoundationTrainingProgrammePage";
import ProductsPage from "src/pages/products/ProductsPage";
import ProgrammeResourcesPage from "src/pages/programme_resources/ProgrammeResourcesPage";
import QuestionBankPage from "src/pages/QuestionBankPage";
import WorkshopsPage from "src/pages/workshops/WorkshopsPage";
import {
  calculationsBank,
  clinicalMepBank,
  clinicalMixedBank,
  clinicalOtcBank,
  clinicalTherapeuticsBank,
} from "src/utils/format_bank";

import TwoFactorAuthPage from "../pages/2fa/TwoFactorAuthPage";
import InitialDisclosureDocumentPage from "../pages/_static/initial_disclosure_document/InitialDisclosureDocumentPage";
import AdminDashboardPage from "../pages/admin_dashboard/AdminDashboardPage";
import CoursePage from "../pages/course/CoursePage";
import CoursesPage from "../pages/courses/CoursesPage";
import ForgotPasswordPage from "../pages/forgot_password/ForgotPasswordPage";
import LandingPage from "../pages/landing/LandingPage";
import { LoginPage } from "../pages/login/LoginPage";
import PreviewQuestionPage from "../pages/PreviewQuestionPage";
import QuizPage from "../pages/QuizPage";
import WelcomePage from "../pages/welcome/WelcomePage";

// mdUpHome true means it shows the route on the left side
// hideHeader true means on that page, don't show the navigation bar and footer
// hideInHeader true means don't show the route/page in the nav bar itself
export const routes = [
  { title: "Home", path: "/", element: <LandingPage />, mdUpHome: true },
  {
    title: "Why Us",
    path: "why-us/",
    element: <WhyUsPage />,
    hideInHeader: true,
  },
  {
    title: "Programme",
    path: "courses/",
    element: <CoursesPage />,
    mdUpHome: true,
    children: [
      {
        title: "Exam Revision Courses",
        path: "courses/",
      },
      {
        title: "Live Online Study Sessions",
        path: "live-online-study-sessions/",
      },
      {
        title: "Programme Resources",
        path: "programme-resources/",
      },
      {
        title: "Question Bank",
        path: "question-bank/",
      },
      {
        title: "Mock Paper",
        path: "mock-paper/",
      },
      {
        title: "Designated Supervisor Programme",
        path: "designated-supervisor-programme/",
      },
      {
        title: "Additional Training Options",
        path: "additional-training-options/",
      },
    ],
  },
  {
    title: "Career Workshops",
    path: "career-workshops/",
    element: <WorkshopsPage />,
    mdUpHome: true,
  },
  {
    title: "Products",
    path: "products/",
    element: <ProductsPage />,
    hideInHeader: true,
  },
  {
    title: "Foundation Training Programme",
    path: "foundation-training-programme/",
    element: <FoundationTrainingProgrammePage />,
    hideInHeader: true,
  },
  {
    title: "Calculations Quiz",
    path: "question-bank/quiz/calculations/:questionIndex?",
    element: <QuizPage bank={calculationsBank} bankUrl="calculations" trial={false} />,
    hideInHeader: true,
  },
  {
    title: "Clinical Mixed Quiz",
    path: "question-bank/quiz/clinical-mixed/:questionIndex?",
    element: <QuizPage bank={clinicalMixedBank} bankUrl="clinical-mixed" trial={false} />,
    hideInHeader: true,
  },
  {
    title: "Clinical Therapeutics Quiz",
    path: "question-bank/quiz/clinical-therapeutics/:questionIndex?",
    element: <QuizPage bank={clinicalTherapeuticsBank} bankUrl="clinical-therapeutics" trial={false} />,
    hideInHeader: true,
  },
  {
    title: "Clinical MEP Quiz",
    path: "question-bank/quiz/clinical-mep/:questionIndex?",
    element: <QuizPage bank={clinicalMepBank} bankUrl="clinical-mep" trial={false} />,
    hideInHeader: true,
  },
  {
    title: "Clinical OTC Quiz",
    path: "question-bank/quiz/clinical-otc/:questionIndex?",
    element: <QuizPage bank={clinicalOtcBank} bankUrl="clinical-otc" trial={false} />,
    hideInHeader: true,
  },
  {
    title: "Quiz",
    path: "question-bank/quiz/try-calculation/:questionIndex?",
    element: <QuizPage bank={calculationsBank} bankUrl="try-calculation" trial={true} />,
    hideInHeader: true,
  },
  {
    title: "Quiz",
    path: "question-bank/quiz/try-clinical/:questionIndex?",
    element: <QuizPage bank={clinicalMixedBank} bankUrl="try-clinical" trial={true} />,
    hideInHeader: true,
  },
  { title: "FAQ", path: "faq/", element: <FAQPage /> },
  { title: "How It Works", path: "how-it-works/", element: <HowItWorksPage /> },
  { title: "About Us", path: "about/", element: <AboutUsPage /> },
  // pages not shown in nav bar / header
  {
    title: "Courses",
    path: "courses/:courseSlug/",
    pathWithoutParams: "courses",
    element: <CoursePage />,
    hideInHeader: true,
  },
  {
    title: "Mock Paper",
    path: "attempt-mock-paper/",
    pathWithoutParams: "attempt-mock-paper",
    element: <AttemptMockPaperPage />,
    hideInHeader: true,
  },
  {
    title: "Calculations Quiz",
    path: "attempt-mock-paper/:questionIndex?",
    element: <AttemptMockPaperPage />,
    hideInHeader: true,
  },
  {
    title: "Preview Question",
    path: "preview-question/:questionId/",
    pathWithoutParams: "preview-question",
    element: <PreviewQuestionPage />,
    hideInHeader: true,
    hideHeader: true,
  },
  {
    title: "Login",
    path: "login/",
    element: <LoginPage />,
    hideHeader: true,
    hideInHeader: true,
  },
  {
    title: "Two-Factor Authentication",
    path: "verify/",
    element: <TwoFactorAuthPage />,
    hideHeader: true,
    hideInHeader: true,
  },
  {
    title: "Forgot Password",
    path: "forgot-password/",
    element: <ForgotPasswordPage />,
    hideHeader: true,
    hideInHeader: true,
  },
  {
    title: "Welcome",
    path: "welcome/",
    pathWithoutParams: "welcome",
    element: <WelcomePage />,
    hideHeader: true,
    hideInHeader: true,
  },
  {
    title: "Set New Password",
    path: "reset-password/",
    pathWithoutParams: "reset-password",
    element: <WelcomePage isSetNewPasswordPage={true} />,
    hideHeader: true,
    hideInHeader: true,
  },
  {
    title: "Admin Dashboard",
    path: "admin-dashboard/",
    pathWithoutParams: "admin-dashboard",
    element: <AdminDashboardPage />,
    hideInHeader: true,
  },
  {
    title: "Privacy Policy",
    path: "privacy-policy/",
    pathWithoutParams: "privacy-policy",
    element: <PrivacyPolicyPage />,
    hideInHeader: true,
  },
  {
    title: "Terms & Conditions",
    path: "terms-and-conditions/",
    pathWithoutParams: "terms-and-conditions",
    element: <TermsAndConditionsPage />,
    hideInHeader: true,
  },
  {
    title: "Status Disclosure",
    path: "status-disclosure/",
    pathWithoutParams: "status-disclosure",
    element: <StatusDisclosurePage />,
    hideInHeader: true,
  },
  {
    title: "Initial Disclosure Document",
    path: "initial-disclosure-document/",
    pathWithoutParams: "initial-disclosure-document",
    element: <InitialDisclosureDocumentPage />,
    hideInHeader: true,
  },
  {
    title: "Mock Paper",
    path: "mock-paper/",
    element: <MockPaperPage />,
    hideInHeader: true,
  },
  {
    title: "Question Bank",
    path: "question-bank/",
    element: <QuestionBankPage />,
    hideInHeader: true,
  },
  {
    title: "Live Online Study Sessions",
    path: "live-online-study-sessions/",
    element: <LiveOnlineStudySessionsPage />,
    hideInHeader: true,
  },
  {
    title: "Programme Resources",
    path: "programme-resources/",
    element: <ProgrammeResourcesPage />,
    hideInHeader: true,
  },
  {
    title: "Additional Training Options",
    path: "additional-training-options/",
    element: <AdditionalTrainingOptionsPage />,
    hideInHeader: true,
  },
  {
    title: "Designated Supervisor Programme",
    path: "designated-supervisor-programme/",
    element: <DesignatedSupervisorProgramme />,
    hideInHeader: true,
  },
  {
    title: "Checkout",
    path: "checkout/",
    element: <CheckoutPage />,
    hideInHeader: true,
  },
  {
    title: "Maintenance",
    path: "maintenance/",
    element: <MaintenanceModePage />,
    hideInHeader: true,
    hideHeader: true,
  },
];

export const footer_routes = [
  { title: "Contact Us", path: "contact/", element: <ContactUsPage /> },
  { title: "Why Us", path: "why-us/", element: <WhyUsPage /> },
  { title: "About Us", path: "about/", element: <AboutUsPage /> },
  { title: "How It Works", path: "how-it-works/", element: <HowItWorksPage /> },
  { title: "FAQ", path: "faq/", element: <FAQPage /> },
];
