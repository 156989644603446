import { Divider, Grid } from "@mui/material";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { FAQContext } from "src/context/FAQContext";
import useResponsive from "src/hooks/useResponsive";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import PageTemplate from "src/pages/PageTemplate";

import Workshops from "./components/Workshops";
import WorkshopsBenefits from "./components/WorkshopsBenefits";
import WorkshopsHowItWorks from "./components/WorkshopsHowItWorks";
import WorkshopsTopSection from "./components/WorkshopsTopSection";
import WorkshopsWhoCanEnrol from "./components/WorkshopsWhoCanEnrol";
import useWorkshops from "./useWorkshops";

const WorkshopsPage = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("Career Workshops");

  const isMdUp = useResponsive("up", "md");
  const isMobile = !isMdUp;
  const { workshops, isLoading } = useWorkshops();

  return (
    <>
      {isLoading && <LoadingScreen />}
      <Helmet link={[{ rel: "canonical", href: "/career-workshops/" }]}>
        <title>Career Workshops | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Explore pharmacy careers options with our workshops at Pre-Reg Shortcuts. Tailored for anyone seeking to learn about pharmacy careers in the UK."
        />
        <meta
          name="keywords"
          content="Pharmacy Careers, Career Options in Pharmacy, Student Workshops, Trainee Pharmacist Support, International Pharmacist Careers, Pharmaceutical Career Paths, Explore Pharmacy Careers, Professional Development for Pharmacists, Pharmacy Job Opportunities, UK Pharmacy Workshop, Pharmacist Jobs UK, Pharmacy Careers in the UK"
        />
      </Helmet>
      <PageTemplate>
        <WorkshopsTopSection />

        <Divider sx={{ mt: { md: 4 } }} style={{ borderColor: "transparent" }} />

        <Grid container spacing={{ xs: 0, md: 10 }}>
          <WorkshopsHowItWorks />
          <Grid item xs={12} md={8}>
            <WorkshopsWhoCanEnrol isMobile={isMobile} />
            <WorkshopsBenefits isMobile={isMobile} />
          </Grid>
        </Grid>

        <Workshops workshops={workshops} />

        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />

        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default WorkshopsPage;
