export default function WorkshopsWhoCanEnrol({ isMobile }) {
  return (
    <>
      <h2
        style={{
          marginBottom: "0.5rem",
        }}
      >
        Who Can Enrol
      </h2>
      <p
        style={{
          marginBottom: isMobile ? "2.5rem" : 0,
        }}
      >
        Pharmacy Students, Trainee Pharmacists, UK & International Pharmacists.
      </p>
    </>
  );
}
