import { Stack } from "@mui/material";

import { FancyPageSubtitle, FancyPageTitle } from "src/pages/landing/components/QuestionBankTopSPRC";

export default function MockPaperTopSection() {
  return (
    <Stack
      sx={{
        alignItems: "center",
        mt: {
          md: 4,
          lg: 2,
        },
      }}
    >
      <FancyPageTitle titles={[`Pass your`, ` Pharmacy Exams `, `with our `, `Foundation Mock Paper`]} />
      <FancyPageSubtitle subtitle={"Ready to test your knowledge? Take our Interactive Mock Paper Now."} />
    </Stack>
  );
}
