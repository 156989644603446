import * as prismic from "@prismicio/client";
import { PrismicProvider } from "@prismicio/react";
import { init, BrowserTracing } from "@sentry/react";
import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./index.css";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

axios.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");

const environment = import.meta.env.VITE_ENV.toLowerCase();
init({
  environment: environment,
  dsn: "https://50c5b1d25f5a355154746cf769f3c2e1@o4505957485510657.ingest.sentry.io/4506434368110592",
  ignoreErrors: [
    "null is not an object (evaluating 'this.el.querySelector')", // known issue with VidstackPlayer
  ],
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const repositoryName = "preregshortcuts";

const prismicAccessToken = import.meta.env.VITE_PRISMIC_ACCESS_TOKEN;
const client = prismic.createClient(repositoryName, {
  accessToken: prismicAccessToken,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <PrismicProvider client={client}>
          <App />
        </PrismicProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
