import RoutedButton from "./RoutedButton";

const EnrolNowButton = () => {
  const path = "/products/";
  const buttonColor = "inherit";
  const style = { width: "102px", height: "42px" };
  return (
    <RoutedButton path={path} buttonColor={buttonColor} buttonStyle={style}>
      Enrol Now
    </RoutedButton>
  );
};

export default EnrolNowButton;
