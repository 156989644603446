import axios, { AxiosError } from "axios";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { errorToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";

import useIsLightMode from "./useIsLightMode";

export const useAxiosAuthInterceptor = (): void => {
  const { clearAuthState } = useContext(AppContext);
  const navigate = useNavigate();

  const isLightMode = useIsLightMode();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        // If the error is 403, we clear the auth state and navigate to the login page
        if (error.response?.status === 403 && !error.config?.url?.startsWith("/api/auth")) {
          clearAuthState();
          navigate("/login/");
          errorToast("Please log in again from this device.", isLightMode);
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);
};
