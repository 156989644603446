import { List } from "@mui/material";

import NavListMobile from "./NavListMobile";

export default function NavSectionVertical({ data }) {
  return (
    <List disablePadding sx={{ px: 2 }}>
      {data.map((list) => (
        <NavListMobile key={list.title + list.path} data={list} />
      ))}
    </List>
  );
}
