import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";

import ManageCourseAccess from "./manage_access/ManageCourseAccess";
import ManageDSPAccess from "./manage_access/ManageDSPAccess";
import ManageMockPaperAccess from "./manage_access/ManageMockPaperAccess";
import ManageQuestionBankAccess from "./manage_access/ManageQuestionBankAccess";
import ManageWorkshopAccess from "./manage_access/ManageWorkshopAccess";

interface ManageUserResourceAccessProps {
  selectedResourceType: ResourceModelType;
  selectedAction: "give" | "edit" | "remove";
  allResources: ResourceModel[];
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

const ManageUserResourceAccess = ({
  selectedResourceType,
  selectedAction,
  allResources,
  userId,
  userName,
  onSuccessCallbackFn,
}: ManageUserResourceAccessProps) => {
  if (selectedResourceType === ResourceModelType.mockPaper) {
    return (
      <ManageMockPaperAccess
        selectedAction={selectedAction}
        allResources={allResources}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
      />
    );
  }

  if (selectedResourceType === ResourceModelType.questionBank) {
    return (
      <ManageQuestionBankAccess
        selectedAction={selectedAction}
        allResources={allResources}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
      />
    );
  }

  if (selectedResourceType === ResourceModelType.course) {
    return (
      <ManageCourseAccess
        selectedAction={selectedAction}
        allResources={allResources}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
      />
    );
  }

  if (selectedResourceType === ResourceModelType.workshop) {
    return (
      <ManageWorkshopAccess
        selectedAction={selectedAction}
        allResources={allResources}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
      />
    );
  }

  if (selectedResourceType === ResourceModelType.designatedSupervisorProgramme) {
    return (
      <ManageDSPAccess
        selectedAction={selectedAction}
        allResources={allResources}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
      />
    );
  }

  return (
    <div>
      <p>Not implemented yet</p>
    </div>
  );
};

export default ManageUserResourceAccess;
