import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";

import TermsAndConditionsContent from "./components/TermsAndConditionsContent";

const TermsAndConditionsPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/terms-and-conditions/" }]}>
        <title>Terms & Conditions | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Explore the legal terms governing your use of Pre-Reg Shortcuts. Understand our policies and commitments to you."
        />
        <meta
          name="keywords"
          content="Legal Agreement between preregshortcuts and trainee Pharmacists, Our Commitments to you, Online Learning Terms, Read Before Enrolling, Terms Agreed When Enrolling"
        />
      </Helmet>
      <PageTemplate title="Terms & Conditions">
        <TermsAndConditionsContent />
      </PageTemplate>
    </>
  );
};

export default TermsAndConditionsPage;
