import { Icon } from "@iconify/react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { forwardRef } from "react";

interface IconifyProps {
  icon: string;
  width?: number;
  sx?: SxProps<Theme>;
  [key: string]: any; // This allows for any other additional props
}

const Iconify = forwardRef<HTMLDivElement, IconifyProps>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, flexShrink: 0, ...sx }} {...other} />
));

Iconify.displayName = "Iconify";

export default Iconify;
