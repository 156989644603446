import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack, Link, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormProvider from "src/components/hook-form";
import { errorToast, loadingToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import EmailField from "src/newComponents/form/EmailField";
import PasswordField from "src/newComponents/form/PasswordField";
import { loginUrl } from "src/urls";

export default function AuthLoginForm() {
  const isLightMode = useIsLightMode();

  const { isAuthenticated } = useContext(AppContext);

  const navigate = useNavigate();

  const search = window.location.href.split("?")[1];

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required.").email("Invalid email address."),
    password: Yup.string().required("Password is required.").min(8, "Your password must be at least 8 characters."),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isAuthenticated) {
      loadingToast("You are already signed in. Redirecting to home page...", isLightMode);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [isAuthenticated]);

  const onSubmit = async ({ email, password }: { email: string; password: string }) => {
    axios
      .post(loginUrl, {
        email: email,
        password: password,
      })
      .then((response) => {
        axios.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");

        if (response.status === 202) {
          reset();
          const nextPage = search ? search : "";
          navigate("/verify/?" + nextPage, {
            state: { email: email },
          });
          return;
        }
      })
      .catch((response) => {
        const error = response.response.data;
        const nonFieldErrors = error.non_field_errors;
        let alertSent = false;
        if (nonFieldErrors) {
          if (nonFieldErrors[0] === "Unable to log in with provided credentials.") {
            errorToast("Incorrect email address or password.", isLightMode);
            window.scrollTo({ top: 0, behavior: "smooth" });
            alertSent = true;
          }
        }
        const detail = error.detail;
        if (detail && typeof detail === "string" && !alertSent) {
          if (detail.toLowerCase().includes("csrf token")) {
            // user is already signed in and trying to sign in again in a new tab
            // refresh the page to redirect to the home page
            window.location.reload();
            return;
          }
        }

        if (!alertSent) {
          errorToast(
            "Failed to authenticate 1/3, please follow all the steps in the support guide on the login page and try again.",
            isLightMode
          );
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        console.log(error);
        reset();
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5} alignItems="flex-end">
        <EmailField hasFocus={true} tabIndex={1} />

        <PasswordField name="password" label="Password" hasFocus={false} tabIndex1={2} tabIndex2={4} />
        <div style={{ alignSelf: "flex-start" }}>
          <Link
            component={RouterLink}
            to={"/forgot-password/"}
            variant="body2"
            underline="always"
            color="text.secondary"
          >
            Forgot password?
          </Link>
          <Typography color="text.secondary" variant="body2" sx={{ mt: 1 }}>
            Having trouble? Refer to our{" "}
            <Link
              component={RouterLink}
              to={"https://drive.google.com/file/d/1ScHxbGhPLUDvq5MqbGgBZkmFFvtrDOr6/view?usp=sharing"}
              underline="always"
              target="_blank"
              color="text.secondary"
            >
              Support Guide
            </Link>{" "}
            for more information.
          </Typography>
        </div>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || isSubmitSuccessful}
          tabIndex={3}
          disabled={isAuthenticated}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
