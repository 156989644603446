import { Box, Accordion, Tooltip, Typography, AccordionSummary, AccordionDetails } from "@mui/material";
import { styled } from "@mui/material/styles";

import Iconify from "src/components/icon/Iconify";
import { errorToast } from "src/components_with_stories/toast";
import { getVideoLockDetails, mapLockRequirementToToastMessage } from "src/config/requirement/requirement";
import useIsLightMode from "src/hooks/useIsLightMode";

import RenderTextAreaContent from "../../../custom_components/RenderTextAreaContent";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.Mui-expanded": {
    overflow: "hidden",
    borderRadius: "8px !important",
    marginBottom: theme.spacing(2.5),
    boxShadow: theme.customShadows.z16,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "&.Mui-expanded": {
    minHeight: "auto",
    backgroundColor: theme.palette.action.selected,
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(2, 0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    "&.Mui-expanded": { margin: theme.spacing(2, 0) },
  },
}));

export default function LessonItem({ lesson, expanded, selected, onExpanded, onOpen, simplifiedView }) {
  const isLightMode = useIsLightMode();

  const { title, description } = lesson;
  const { isLocked, lockReason, lockRequirement } = getVideoLockDetails(lesson.accessible_video_url);
  const tooltipTitle = isLocked ? lockReason : "Play";

  const handleOpen = () => {
    if (isLocked) {
      const message = mapLockRequirementToToastMessage(lockRequirement);
      errorToast(message, isLightMode);
    } else {
      onOpen();
    }
  };

  if (simplifiedView) {
    return (
      <div style={{ display: "flex", marginTop: "1rem", gap: "0.5rem" }}>
        <Icon
          tooltipTitle={tooltipTitle}
          isLocked={isLocked}
          handleOpen={handleOpen}
          selected={selected}
          position="relative"
        />
        <div>
          <AccordionTitle isLocked={isLocked} title={title} selected={selected} simplifiedView={simplifiedView} />
          <RenderTextAreaContent content={description} />
        </div>
      </div>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Icon
        tooltipTitle={tooltipTitle}
        isLocked={isLocked}
        handleOpen={handleOpen}
        selected={selected}
        position="absolute"
      />
      <StyledAccordion expanded={expanded} onChange={onExpanded}>
        <StyledAccordionSummary>
          <AccordionTitle isLocked={isLocked} title={title} selected={selected} simplifiedView={simplifiedView} />
          <AccordionExpandCollapseIcon expanded={expanded} />
        </StyledAccordionSummary>

        <AccordionDetails style={{ padding: "0 1rem 1rem 1rem" }}>
          <RenderTextAreaContent content={description} />
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
}

const Icon = ({ tooltipTitle, isLocked, handleOpen, selected, position }) => {
  const icon = selected ? "carbon:pause-outline" : "carbon:play";

  return (
    <Tooltip title={tooltipTitle}>
      <Iconify
        width={24}
        icon={isLocked ? "carbon:locked" : icon}
        onClick={handleOpen}
        sx={{
          mr: position === "absolute" ? 2 : 1,
          top: position === "absolute" ? 15 : 0,
          left: position === "absolute" ? 8 : 0,
          zIndex: 9,
          cursor: "pointer",
          position: position,
          ...(selected && {
            color: "primary.main",
          }),
        }}
      />
    </Tooltip>
  );
};

function AccordionTitle({ isLocked, title, selected, simplifiedView }) {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        flexGrow: 1,
        pl: simplifiedView ? 0 : 3,
        ...(selected &&
          !isLocked && {
            color: "primary.main",
          }),
      }}
    >
      {title}
    </Typography>
  );
}

function AccordionExpandCollapseIcon({ expanded }) {
  return (
    <Iconify
      icon={expanded ? "carbon:chevron-down" : "carbon:chevron-right"}
      sx={{
        color: "text.secondary",
      }}
    />
  );
}
