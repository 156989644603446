import { Button } from "@mui/material";
import React, { useState } from "react";

import MoreInformationModal from "../MoreInformationModal";

interface LearnMoreButtonWithModalProps {
  title: string;
  content: React.ReactNode;
  rightMargin?: boolean;
  buttonText?: string;
  contained?: boolean;
  size?: "small" | "medium";
}

const LearnMoreButtonWithModal = ({
  title,
  content,
  rightMargin,
  buttonText = "Learn More",
  contained = false,
  size = "small",
}: LearnMoreButtonWithModalProps) => {
  const [open, setOpen] = useState(false);

  const style = rightMargin ? { marginRight: "1rem" } : {};

  return (
    <>
      <Button
        variant={contained ? "contained" : "outlined"}
        color="secondary"
        style={style}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
        size={size}
      >
        {buttonText}
      </Button>
      {open && (
        <MoreInformationModal
          title={title}
          content={content}
          handleClose={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default LearnMoreButtonWithModal;
