import { Grid } from "@mui/material";

import StepLabel from "src/pages/workshops/components/StepLabel";

export default function DSPHowItWorks() {
  return (
    <Grid item xs={12} md={4}>
      <h2
        style={{
          marginBottom: "1.5rem",
        }}
      >
        How It Works
      </h2>
      <StepLabel
        step="1"
        title="Step 1"
        body="Enrol on our designated supervisor programme at any point throughout the year."
      />
      <StepLabel step="2" title="Step 2" body="Access the programme instantly." />
      <StepLabel step="3" title="Step 3" body="Join the community and start your journey of development." />
      <p>
        Note: If you enrol your Trainee on our Foundation Pharmacist Training Programme we include our Designated
        Supervisor Programme for free.
      </p>
    </Grid>
  );
}
