import { useState, useEffect } from "react";

function useOffSetTop() {
  const [isOffset, setIsOffset] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = document.documentElement.scrollTop;
      setIsOffset(scrollY > 0);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isOffset;
}

export { useOffSetTop };
