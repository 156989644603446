import React, { useEffect } from "react";

import useIsLightMode from "src/hooks/useIsLightMode";

const TrustBox = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const [trustpilotLoaded, setTrustpilotLoaded] = React.useState(false);
  const [reloadAttempt, setReloadAttempt] = React.useState(0);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ref = React.useRef(null);
  const isLightMode = useIsLightMode();

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
      setTrustpilotLoaded(true);
    } else {
      setTrustpilotLoaded(false);
      setReloadAttempt(1);
    }
  }, []);

  const triggerReload = (attemptNum) => {
    setTimeout(() => {
      setReloadAttempt(attemptNum);
    }, attemptNum * 100); // if first attempt, wait 100ms, if second attempt, wait 200ms, etc.
  };

  useEffect(() => {
    if (reloadAttempt !== 0 && trustpilotLoaded !== true) {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
        setTrustpilotLoaded(true);
        setReloadAttempt(0);
      } else {
        if (reloadAttempt < 25) {
          triggerReload(reloadAttempt + 1);
        }
      }
    }
  }, [reloadAttempt, trustpilotLoaded]);

  return (
    <div
      id="trustbox"
      ref={ref}
      style={{ width: width < 700 ? "100%" : "900px" }}
      data-theme={isLightMode ? "light" : "dark"}
      data-locale="en-GB"
      data-template-id="5406e65db0d04a09e042d5fc"
      data-businessunit-id="607591e0f2eabb0001340c75"
      data-style-height="28px"
      data-style-width="100%"
    >
      <a href="https://uk.trustpilot.com/review/preregshortcuts.com" target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
