import { Stack, Typography } from "@mui/material";

import useIsLightMode from "src/hooks/useIsLightMode";
import useResponsive from "src/hooks/useResponsive";
import ResourceModel from "src/models/ResourceModel";

const RenderCost = ({ resourceModel }: { resourceModel: ResourceModel }) => {
  const isMdUp = useResponsive("up", "md");
  const isLightMode = useIsLightMode();

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} sx={{ position: "relative" }}>
      <Typography
        variant="h6"
        component="span"
        style={{ marginRight: "0.5rem", marginTop: "4px" }}
        sx={{ color: "#FA541C" }}
      >
        <s>£{resourceModel.originalCost}</s>
      </Typography>

      <Typography variant="h4" component="span" sx={{ color: "#009d47" }}>
        £
      </Typography>

      <Typography variant="h3" component="span" sx={{ color: "#009d47" }}>
        {resourceModel.cost}
      </Typography>

      <span
        style={{
          color: isLightMode ? "darkblue" : "lightblue",
          fontSize: "0.9rem",
          fontWeight: "bold",
          position: "absolute",
          top: isMdUp ? "2.75rem" : "2.25rem",
          left: isMdUp ? "1.25rem" : "0.75rem",
        }}
      >
        Save £{resourceModel.originalCost - resourceModel.cost}
      </span>
    </Stack>
  );
};

export default RenderCost;
