import AnswerLink from "src/config/faq/answer/AnswerLink";

const SummarisedTnC = () => {
  return (
    <div>
      <ol>
        <li>
          <b>Acceptance of Terms: </b>
          By accessing and using our e-learning website, you agree to comply with these terms and conditions.
        </li>

        <li>
          <b>User Account: </b>
          You are responsible for maintaining the confidentiality of your account information and ensuring its accuracy.
          You must be at least 18 years old or have parental consent to create an account.
        </li>

        <li>
          <b>Account Sharing: </b>
          You cannot share your login details with anyone else. It is your responsibility to ensure no one gains access
          to your password or login details. Failing to do this will result in termination of your access and immediate
          removal from the programme.
        </li>

        <li>
          <b>Content Ownership: </b>
          All content provided on our website, including text, graphics, videos, and other materials, is owned by
          Preregshortcuts Ltd or our licensors. You may not reproduce, distribute, modify, or create derivative works
          without our prior written consent.
        </li>

        <li>
          <b>User Conduct: </b>
          You agree to use our website for lawful purposes only. You must not engage in any activity that may disrupt or
          interfere with the functioning of our website or infringe upon the rights of others.
        </li>

        <li>
          <b>Payment and Refunds: </b>
          If you purchase any courses or services on our website, you agree to pay the specified fees. Refunds may be
          granted under certain circumstances, this subject to our refund policy set in the full terms and conditions
          below or can be viewed on our <AnswerLink href="/faq/">FAQ page</AnswerLink> under Refund and Cancellation
          Policy.
        </li>

        <li>
          <b>Intellectual Property: </b>
          Our website may contain trademarks, logos, and copyrighted materials owned by us or third parties. You are
          prohibited from using these without obtaining prior written permission.
        </li>

        <li>
          <b>Privacy: </b>
          We collect and process personal information in accordance with our Privacy Policy. By using our website, you
          consent to such collection and processing.
        </li>

        <li>
          <b>Third-Party Links: </b>
          Our website may contain links to third-party websites or resources. We are not responsible for the content or
          availability of these external sites and do not endorse or guarantee their accuracy or reliability.
        </li>

        <li>
          <b>Limitation of Liability: </b>
          We strive to provide accurate and reliable information, but we do not guarantee the completeness, accuracy, or
          reliability of any content on our website. We shall not be liable for any direct, indirect, incidental, or
          consequential damages arising from your use of our website.
        </li>

        <li>
          <b>Termination: </b>
          We reserve the right to suspend or terminate your access to our website at any time without prior notice if
          you violate these terms and conditions.
        </li>

        <li>
          <b>Governing Law: </b>
          These terms and conditions shall be governed by and construed in accordance with English law. Any disputes
          arising from these terms shall be subject to the exclusive jurisdiction of the courts in England.
        </li>

        <li>
          <b>Modifications: </b>
          We reserve the right to modify or update these terms and conditions at any time. It is your responsibility to
          review them periodically for any changes.
        </li>
      </ol>
    </div>
  );
};

export default SummarisedTnC;
