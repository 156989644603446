import { Badge, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import Iconify from "src/components/icon/Iconify";

const ShoppingCart = ({ numberOfItemsInBasket }) => {
  return (
    <Badge badgeContent={numberOfItemsInBasket} color="error">
      <IconButton component={RouterLink} to="checkout/" size="small" color="inherit" sx={{ p: 0 }}>
        <Iconify icon="carbon:shopping-cart" width={24} />
      </IconButton>
    </Badge>
  );
};

export default ShoppingCart;
