import React from "react";
import { UseFormReturn, FormProvider as Form } from "react-hook-form";

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: () => void;
  styles?: React.CSSProperties;
};

export default function FormProvider({ children, onSubmit, methods, styles }: Props) {
  return (
    <Form {...methods}>
      <form style={styles} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
