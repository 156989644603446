import { toast } from "react-hot-toast";

import { DARK_MODE_TOAST_STYLE, LIGHT_MODE_TOAST_STYLE } from "src/App";
import { FOUR_SECONDS, TWENTY_SECONDS } from "src/utils/duration";

export const criticalToast = (message, finalOrder) => {
  const isDev = import.meta.env.VITE_ENV.toLowerCase() === "development";
  const duration = isDev ? 1000 : TWENTY_SECONDS;

  setTimeout(() => {
    toast.error(message, {
      duration: duration,
      className: "errorToast",
      style: {
        background: "#8b0000",
        color: "#fff",
        maxWidth: "90vw",
      },
    });
  }, finalOrder * 500);
};

// message and isLightMode are required fields, duration is optional

export const errorToast = (message, isLightMode, duration) => {
  const actualDuration = duration ? duration : undefined;
  const isDev = import.meta.env.VITE_ENV.toLowerCase() === "development";
  toast.error(message, {
    style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
    duration: isDev ? 1000 : actualDuration,
    className: "errorToast",
  });
};

export const warningToast = (message, isLightMode, duration) => {
  toast(message, {
    style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
    duration: duration ? duration : undefined,
    className: "warningToast",
    icon: "⚠️",
  });
};

export const successToast = (message, isLightMode, duration) => {
  toast.success(message, {
    style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
    duration: duration ? duration : undefined,
    className: "successToast",
  });
};

export const loadingToast = (message, isLightMode) => {
  toast.loading(message, {
    style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
    duration: FOUR_SECONDS,
    className: "loadingToast",
  });
};

export const infoToast = (message, isLightMode, duration) => {
  toast(message, {
    style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
    duration: duration ? duration : undefined,
    className: "infoToast",
    icon: "ℹ️",
  });
};

export const dismissAllToasts = () => {
  toast.dismiss();
};
