import { Divider, Stack, Card, Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import placeholderImage from "src/assets/placeholder.jpeg";
import Image from "src/components/image/Image";
import TextMaxLine from "src/components/text-max-line";
import { resourceModelTypeToRouteMap } from "src/models/ResourceModelType";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

export default function FeaturedProduct({ resourceModel }) {
  const canBuyResource = resourceCanBePurchased(resourceModel);

  const title = resourceModel.title;
  const description = resourceModel.checkoutDescription;

  const page = `/${resourceModelTypeToRouteMap[resourceModel.type]}/`;

  return (
    <Link component={RouterLink} to={page} color="inherit" sx={{ "&:hover": { textDecoration: "none" } }}>
      <Card
        sx={{
          display: { sm: "flex" },
          "&:hover": {
            boxShadow: (theme) => theme.customShadows.z24,
          },
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexShrink: { sm: 0 } }}>
          <Image
            alt={title}
            src={resourceModel?.image ?? placeholderImage}
            sx={{ height: 1, objectFit: "cover", width: { sm: 1 } }}
            loading="eager"
          />
        </Box>

        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={{ xs: 3, sm: 2.5 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <TextMaxLine variant="h6" line={1}>
                {title}
              </TextMaxLine>
            </Stack>

            <TextMaxLine variant="body2" color="text.secondary">
              {description}
            </TextMaxLine>
          </Stack>

          {canBuyResource && (
            <>
              <Divider sx={{ borderStyle: "dashed", display: "block" }} />
              <Stack
                direction={{ xs: "column", md: "row" }}
                flexWrap="wrap"
                sx={{
                  color: "text.disabled",
                  "& > *:not(:last-child)": { mr: 2.5 },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ mt: { xs: 1, md: 0 } }}>
                  <ResourceCost
                    cost={resourceModel.cost}
                    originalCost={resourceModel.originalCost}
                    paddingTop={false}
                  />
                  <AddResourceToBasket resourceModel={resourceModel} />
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Card>
    </Link>
  );
}
