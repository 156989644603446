// urls for bulk upload
export const adminBulkUploadCalculationsUrl = `/api/bulk_upload_questions/calculations/`;
export const adminBulkUploadClinicalUrl = `/api/bulk_upload_questions/clinical/`;
export const mockPaperBulkUploadCalculationsUrl = `/api/bulk_upload_mock_paper/calculations/`;
export const mockPaperBulkUploadClinicalUrl = `/api/bulk_upload_mock_paper/clinical/`;

// admin only endpoints
export const adminDashboardEndpoint = "/api/admin/get_users/";
export const adminGetQuestionEndpoint = `/api/admin/get_question/`;
export const adminGetBankQuestionsMetaData = `/api/admin/get_all_bank_questions_meta_data/`;
export const adminGetAllQuestionSetQuestions = `/api/admin/get_all_question_set_questions_meta_data/`;

// Auth endpoints
export const loginUrl = `/api/auth/login/`;
export const twoFactorAuthEndpoint = `/api/auth/verify-2fa/`;
export const resendAuthEndpoint = `/api/auth/request-new-otp/`;
export const logoutEndpoint = `/api/auth/logout/`;
export const userDetailsEndpoint = `/api/auth/user/`;
export const confirmPasswordResetEndpoint = `/api/auth/password/reset/confirm/`;
export const requestPasswordResetEndpoint = `/api/auth/request_password_reset/`;
export const changePasswordEndpoint = `/api/auth/password/change/`;
export const userAccountExistsEndpoint = `/api/user_account_exists/`;

// Content endpoints
export const saveLessonProgressEndpoint = `/api/lesson_progress/save/`;
export const getLessonProgressEndpoint = `/api/lesson_progress/get/`;
export const getQuestionsEndpoint = `/api/questions/get/`;
export const saveQuizAttemptEndpoint = `/api/quiz_attempt/save/`;
export const getQuizAttemptsEndpoint = `/api/quiz_attempt/get/`;
export const reportQuestionEndpoint = `/api/questions/report/`;

export const workshopsEndpoint = `/api/workshops/`;
export const mockPapersEndpoint = `/api/mock_papers/`;
export const startMockPaperEndpoint = `/api/user_start_question_set/`;

// Payment endpoints
export const createCheckoutSessionEndpoint = `/api/checkout/create_session/`;
