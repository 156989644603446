import { Button, Grid, Stack, Typography } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { QUIZ_NOT_STARTED } from "src/components_with_stories/QuizStates";
import { errorToast, successToast } from "src/components_with_stories/toast";
import { AccessContext } from "src/context/AccessContext";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import PageTemplate from "src/pages/PageTemplate";
import { getBankName } from "src/utils/format_bank";

import QuizAttempts from "../components_with_stories/QuizAttempts";
import Quiz from "../custom_components/Quiz";
import { getQuizAttemptsEndpoint } from "../urls";

const QuizPage = ({ bank, bankUrl, trial }) => {
  const { isAuthenticated } = useContext(AppContext);
  const { getResourceModelByResourceType } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceType("questionBank");

  const canAccessFullQB = resourceModel?.access?.userHasValidAccess ?? false;

  const [attempts, setAttempts] = useState(undefined);
  const [numAttemptsToShow, setNumAttemptsToShow] = useState(3);

  const quizState = useLocalStorage("qb_quizState", QUIZ_NOT_STARTED)[0];
  const quizStarted = quizState !== QUIZ_NOT_STARTED;

  const isLightMode = useIsLightMode();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(getQuizAttemptsEndpoint)
        .then((response) => {
          setAttempts(response.data);
        })
        .catch(() => {
          errorToast("Error loading quiz attempts.", isLightMode);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (canAccessFullQB && trial) {
    // user who has full access tries trial mode
    navigate("/question-bank/", { replace: true });
    return <></>;
  } else if (!canAccessFullQB && !trial) {
    // user who does not have full access tries normal mode
    if (isAuthenticated) {
      // if that user is signed in, send them to QB landing.
      navigate("/question-bank/", { replace: true });
      return <></>;
    }
    navigate(`/login/?next=question-bank/quiz/${bankUrl}/`, { replace: true });
    return <></>;
  }

  const canonical = `/question-bank/quiz/${bankUrl}/`;

  let description = "Quiz page description.";
  let keywords = "Keywords";
  if (bankUrl === "calculations") {
    description =
      "Practice Pharmacy Calculations for the GPhC registration assessment and pharmacy exams with Pre-Reg Shortcuts.";
    keywords = "Calculations, GPhC framework, GPhC practice questions";
  } else if (bankUrl === "clinical-mixed") {
    description =
      "Explore mixed clinical scenarios for the GPhC registration assessment and pharmacy exams. Test your knowledge with BNF, MEP, and OTC questions.";
    keywords = "Over the counter, Medicine ethics and practice, Single best answer, Extended matching, Therapeutics";
  } else if (bankUrl === "clinical-therapeutics") {
    description =
      "Discover comprehensive clinical therapeutics guidance for the GPhC registration assessment and pharmacy exams with Pre-Reg Shortcuts. Practice BNF principles and treatment strategies.";
    keywords = "Single best answer, Extended matching, Therapeutics, BNF";
  } else if (bankUrl === "clinical-mep") {
    description =
      "Improve ethics and practice skills for the GPhC registration assessment and pharmacy exams with Pre-Reg Shortcuts. Access practical questions and scenarios for MEP preparation.";
    keywords = "Medicines Practice & ethics (MEP), Pharmacy law and ethics";
  } else if (bankUrl === "clinical-otc") {
    description =
      "Enhance your Over-the-Counter knowledge for the GPhC registration assessment and pharmacy exams with Pre-Reg Shortcuts. Practice OTC medication scenarios and prepare confidently.";
    keywords = "Over the counter (OTC), minor ailments";
  } else if (bankUrl === "try-calculation") {
    description =
      "Sample Pharmacy Calculations questions for pharmacy exams and the GPhC registration assessment. Explore our maths practice resources and boost your confidence.";
    keywords = "Pharmaceutical Calculations, GPhC practice questions calculations";
  } else if (bankUrl === "try-clinical") {
    description =
      "Get a sneak peek into clinical questions for the GPhC registration assessment on Pre-Reg Shortcuts. Try our learning resources to prepare effectively.";
    keywords = "MEP, OTC, therapeutics, BNF, Extended matching questions, Single best answer questions, GPhC framework";
  }

  const showQuizAttempts = !quizStarted;
  const attemptsSplit = showQuizAttempts ? 5 : 0; // takes 5/12 of the screen
  const attemptsToShow = attempts ? attempts.slice(0, numAttemptsToShow) : [];
  const noMoreAttemptsToShow = numAttemptsToShow >= attempts?.length;
  const showLoadMoreButton = attempts && !noMoreAttemptsToShow;

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: canonical }]}>
        <title>
          {trial ? "Trial" : ""} {getBankName(bank)} Quiz | Pre-Reg Shortcuts
        </title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      {resourceModel === null && <LoadingScreen />}
      <PageTemplate>
        <Grid
          container
          spacing={{
            xs: 4,
            md: 6,
          }}
          direction="row"
        >
          {/* make the Quiz grid smaller when attempts show */}
          <Grid item xs={12} md={12 - attemptsSplit}>
            <Quiz trial={trial} bank={bank} bankUrl={bankUrl} />
          </Grid>
          {showQuizAttempts && (
            <Grid item xs={12} md={attemptsSplit}>
              <Typography variant="h3">Quiz Attempts</Typography>

              {isAuthenticated ? (
                <Typography variant="body1" sx={{ mb: 1, mt: 0.75 }}>
                  {attempts?.length > 0
                    ? "Your latest quiz attempts are shown below."
                    : "You have not attempted any quizzes yet. Try a quiz now to see your previous attempts here."}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ mb: 1, mt: 0.75 }}>
                  Login to see your previous quiz attempts, or check out our{" "}
                  <a href="/products/" className="faqPageLink" target="_blank">
                    Products page
                  </a>{" "}
                  to purchase the full Question Bank.
                </Typography>
              )}

              <QuizAttempts attempts={attemptsToShow} />

              {showLoadMoreButton && (
                <Stack alignItems="center">
                  <Button
                    variant="outlined"
                    size="large"
                    color="inherit"
                    sx={{ mt: 1.5 }}
                    onClick={() => {
                      const newNumAttemptsToShow = numAttemptsToShow + 3;
                      if (newNumAttemptsToShow > attempts.length) {
                        setNumAttemptsToShow(attempts.length);
                        successToast(`Showing all ${attempts.length} quiz attempts.`, isLightMode);
                      } else {
                        setNumAttemptsToShow(newNumAttemptsToShow);
                        successToast(`Showing ${newNumAttemptsToShow}/${attempts.length} quiz attempts.`, isLightMode);
                      }
                    }}
                  >
                    Load More
                  </Button>
                </Stack>
              )}
            </Grid>
          )}
        </Grid>
      </PageTemplate>
    </>
  );
};

export default QuizPage;
