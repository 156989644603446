import { Box, BoxProps, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";

import logoDark from "./logo_dark.png";
import logoLight from "./logo_light.png";

interface LogoProps extends BoxProps {
  single?: boolean;
}

function Logo({ sx }: LogoProps) {
  const { palette } = useTheme();
  const mode = palette.mode;
  const isLightMode = mode === "light";

  return (
    <Link component={RouterLink} to="/" color="inherit" aria-label="go to homepage" sx={{ lineHeight: 0 }}>
      <Box
        sx={{
          width: 110,
          lineHeight: 0,
          cursor: "pointer",
          display: "inline-flex",
          ...sx,
        }}
      >
        <img
          src={isLightMode ? logoLight : logoDark}
          title="Pre-Reg Shortcuts Logo"
          alt="Pre-Reg Shortcuts Logo"
          width={110}
          height={57}
          loading="eager"
        />
      </Box>
    </Link>
  );
}

export default memo(Logo);
