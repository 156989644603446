import FullTnC from "./FullTnC";
import SummarisedTnC from "./SummarisedTnC";

const TermsAndConditionsContent = () => {
  return (
    <>
      <div>
        <p>
          This page contains a <b>summary</b> of the terms and conditions and the <b>full</b> terms and conditions.{" "}
        </p>
        <b>
          By using our e-learning website, you acknowledge that you have read, understood, and agreed to the <b>full</b>{" "}
          terms and conditions.
        </b>

        <h2>Terms and Conditions Summary</h2>
        <SummarisedTnC />
        <br />
        <h2>Full Terms and Conditions</h2>
        <FullTnC />
      </div>
    </>
  );
};

export default TermsAndConditionsContent;
