import axios from "axios";

import ConfigType from "src/models/ConfigType";
import ConfigurationModel from "src/models/ConfigurationModel";

interface ConfigurationDto {
  type: string;
  accessible_value: string;
}

interface IConfigurationRepository {
  getConfigurations(): Promise<ConfigurationModel[]>;
}

class ConfigurationRepository implements IConfigurationRepository {
  async getConfigurations() {
    try {
      const response = await axios.get<ConfigurationDto[]>("/api/configurations/");
      if (response.status !== 200) throw new Error();
      const configs = response.data.map((c) => mapDtoToModel(c));
      return configs;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch configurations.");
    }
  }
}

export default ConfigurationRepository;

const mapDtoToModel = (dto: ConfigurationDto): ConfigurationModel => {
  return {
    type: dto.type as ConfigType,
    value: dto.accessible_value,
  };
};
