import { Link, ListItemText, ListItemIcon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { StyledNavItem } from "./styles";

export default function NavItem({ item, active, isExternalLink, ...other }) {
  const { title, path, icon } = item;

  const renderContent = (
    <StyledNavItem active={active} {...other}>
      <ListItemIcon> {icon} </ListItemIcon>

      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );

  // ExternalLink
  if (isExternalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Default
  return (
    <Link component={RouterLink} to={path} underline="none">
      {renderContent}
    </Link>
  );
}
