import { ReactNode } from "react";

interface AnswerLinkProps {
  href: string;
  children: ReactNode;
}

const AnswerLink = ({ href, children }: AnswerLinkProps) => {
  return (
    <a href={href} className="faqPageLink" target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default AnswerLink;
