import { Box, CircularProgress, circularProgressClasses, Divider, Typography } from "@mui/material";

import ChapterResource from "src/custom_components/courses/ChapterResource";
import ChapterWeight from "src/custom_components/courses/ChapterWeight";
import CustomAccordion from "src/custom_components/courses/CustomAccordion";
import RenderTextAreaContent from "src/custom_components/RenderTextAreaContent";
import useResponsive from "src/hooks/useResponsive";
import ChapterModel from "src/models/ChapterModel";
import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";

import LessonList from "./LessonList";

interface ChapterProps {
  chapter: ChapterModel;
  chapterIdx: number;
  showChapterWeight: boolean;
  simplifiedView: boolean;
  chapterProgress: number | null;
  updateLessonProgress?: (lessonProgress: CourseLessonProgressModel) => void;
}

const Chapter = ({
  chapter,
  chapterIdx,
  showChapterWeight,
  simplifiedView,
  chapterProgress,
  updateLessonProgress,
}: ChapterProps) => {
  if (simplifiedView) {
    return (
      <div style={{ marginBottom: "3rem" }}>
        <AccordionTitle
          chapter={chapter}
          chapterIdx={chapterIdx}
          showChapterWeight={showChapterWeight}
          simplifiedView={simplifiedView}
          chapterProgress={chapterProgress}
        />
        <LessonList
          lessons={chapter.lessons}
          simplifiedView={simplifiedView}
          updateLessonProgress={updateLessonProgress}
        />
        <Divider sx={{ mt: 1 }} style={{ borderColor: "transparent" }} />
        <RenderResources chapter={chapter} />
      </div>
    );
  }
  return (
    <div style={{ marginBottom: "2rem", borderBottom: "1px solid lightgrey" }}>
      <CustomAccordion
        removeMargin={false}
        key={chapter.id}
        title={
          <AccordionTitle
            chapter={chapter}
            chapterIdx={chapterIdx}
            showChapterWeight={showChapterWeight}
            simplifiedView={simplifiedView}
            chapterProgress={chapterProgress}
          />
        }
        content={
          <>
            <LessonList
              lessons={chapter.lessons}
              simplifiedView={simplifiedView}
              updateLessonProgress={updateLessonProgress}
            />
            <RenderResources chapter={chapter} />
          </>
        }
      />
    </div>
  );
};

export default Chapter;

function AccordionTitle({
  chapter,
  chapterIdx,
  showChapterWeight,
  simplifiedView,
  chapterProgress,
}: {
  chapter: ChapterModel;
  chapterIdx: number;
  showChapterWeight: boolean;
  simplifiedView: boolean;
  chapterProgress: number | null;
}) {
  const title = simplifiedView ? "Module" : "Chapter";
  const showChapterProgress = chapterProgress !== null;
  const isSmDown = useResponsive("down", "sm");
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "0.5rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ mb: 0 }}>
          {title} {chapterIdx + 1}: {chapter.title}
        </Typography>
        {(showChapterProgress || showChapterWeight) && (
          <div
            style={{
              display: "flex",
              flexDirection: isSmDown ? "column" : "row-reverse",
              alignItems: "center",
              gap: isSmDown ? "2px" : "1rem",
            }}
          >
            {chapterProgress !== null && <CircularProgressWithLabel value={chapterProgress} />}
            {showChapterWeight && <ChapterWeight chapter={chapter} />}
          </div>
        )}
      </div>
      <RenderTextAreaContent content={chapter.description} />
    </div>
  );
}

function RenderResources({ chapter }: { chapter: ChapterModel }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
      {chapter.resource_description && chapter.accessible_resource_url && (
        <ChapterResource
          resourceDescription={chapter.resource_description}
          accessibleResourceUrl={chapter.accessible_resource_url}
          isProgrammeResource={false}
        />
      )}
      {chapter.resource_description_2 && chapter.accessible_resource_2_url && (
        <ChapterResource
          resourceDescription={chapter.resource_description_2}
          accessibleResourceUrl={chapter.accessible_resource_2_url}
          isProgrammeResource={false}
        />
      )}
      {chapter.resource_description_3 && chapter.accessible_resource_3_url && (
        <ChapterResource
          resourceDescription={chapter.resource_description_3}
          accessibleResourceUrl={chapter.accessible_resource_3_url}
          isProgrammeResource={false}
        />
      )}
    </div>
  );
}

function CircularProgressWithLabel({ value }: { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: "rgb(0, 157, 71)",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${value.toFixed(0)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
