import { Stack } from "@mui/material";

import { mockPaperBulkUploadCalculationsUrl, mockPaperBulkUploadClinicalUrl } from "src/urls";

import QuestionSetSummary from "./components/QuestionSetSummary";
import UploadCsv from "./UploadCsv";

const MockPaperAdminDashboard = ({ questionSetsMetaData }) => {
  if (!questionSetsMetaData) return <div>Loading...</div>;

  return (
    <>
      <Stack spacing={2} direction="row" sx={{ mb: 3.5 }}>
        <UploadCsv type="Calculations" endpointUrl={mockPaperBulkUploadCalculationsUrl} />
        <UploadCsv type="Clinical" endpointUrl={mockPaperBulkUploadClinicalUrl} />
      </Stack>

      {questionSetsMetaData.map((qs) => {
        return (
          <QuestionSetSummary
            key={qs.id}
            expectedNumQuestions={qs.mock_paper_order === 1 ? 40 : 120}
            questionsMetaData={qs.questions}
            title={`${qs.title} (Mock Paper Order ${qs.mock_paper_order})`}
          />
        );
      })}
    </>
  );
};

export default MockPaperAdminDashboard;
