import { useContext, useEffect } from "react";

import { AccessContext } from "src/context/AccessContext";
import CustomModal from "src/newComponents/CustomModal";

import RenderResourceAccesses from "./RenderResourceAccesses";

interface RemainingAccessModalProps {
  handleModalClose: () => void;
}

const RemainingAccessModal = ({ handleModalClose }: RemainingAccessModalProps) => {
  const { resources, refreshResources } = useContext(AccessContext);

  useEffect(() => {
    refreshResources();
  }, []);

  return (
    <CustomModal title="Remaining Access" isOpen={true} handleClose={handleModalClose} maxWidth="md">
      {resources ? <RenderResourceAccesses resources={resources} /> : <div>Loading...</div>}
    </CustomModal>
  );
};

export default RemainingAccessModal;
